import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPagination,
  FluxPreloader,
  FluxImage,
} from 'vue-flux';
export default {
  props: ["bar", "captions", "images"],
  components: {
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader,
    FluxImage,
  },
  data: () => ({
    options: {
      allowFullscreen: true,
      allowToSkipTransition: true,
      autohideTime: 7000,
      autoplay: true,
      bindKeys: false,
      delay: 7000,
      enableGestures: false,
      infinite: true,
      lazyLoad: true,
      lazyLoadAfter: 3,
    },
    transitions: [
      'blinds3d',
      'blocks2',
      'book',
      'cube',
      'round2',
      'swipe',
      'warp',
      'wave',
    ],
  }),
  methods: {
    leftMargin() {
      //console.log(screen.width / 2);
      return ((screen.width / 2) - 170);
    },
    view(text){
      this.$router.push('/view/'+ btoa(text))
    },
    viewData(datos, title){
      datos.titulo = title;
      //console.log('datos');
      //console.log(datos);
      // //console.log(title);
      this.$router.push('/datos/'+btoa(JSON.stringify(datos)))
    }
  },
}
import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "# placa",
          align: "center",
          sortable: true,
          value: "no_plate",
        },
				{
					text: "Marca",
					align: "center",
					sortable: true,
					value: "brand_vehicle.name",
				},
				{
					text: "Tipo",
					align: "center",
					sortable: true,
					value: "type_vehicle.name",
				},
				{
					text: "# Chasis",
					align: "center",
					sortable: true,
					value: "no_chassis",
				},
				{
					text: "color",
					align: "center",
					sortable: true,
					value: "color",
				},
				{
					text: "Foto",
					align: "center",
					sortable: true,
					value: "image",
				},
				{
					text: "Status",
					align: "center",
					sortable: true,
					value: "status",
				},
				{
					text: "Capacidad",
					align: "center",
					sortable: true,
					value: "no_passengers",
				},
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],

			items: [],

			item_aux: {
				id: -1,
				no_plate: '',
				brand_vehicle_id: -1,
				type_vehicle_id: -1,
				no_chassis: '',
				color: '#ffffff',
				image: '',
				status: '',
				no_passengers: '',

			},
			dialog_edit: false,
			isEdit: false,
			isDelete: false,

			marcas: [],
			tipos: [],
			valid: true,
    };
  },
  async created() {
		// crea una promesa para obtener las marcas y los tipos
		let p1 = this.getBrandVehicle();
		let p2 = this.getTypeVehicle();
		let p3 = this.getVehicle();

		await Promise.all([p1, p2, p3]);

  },

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},
	},
	
  methods: {

		async getBrandVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getBrandVehicle();
				this.marcas = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener las marcas", { timeout: 2000 });
				this.loading = false;
			}
		},

		async getTypeVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getTypeVehicle();
				this.tipos = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los tipos", { timeout: 2000 });
				this.loading = false;
			}
		},

		async getVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getVehicle();
				this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los usuarios", { timeout: 2000 });
				this.loading = false;
			}
		}, 

		async deleteVehicle(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.deleteVehicle(item.id);
				this.$toast.success("Marca eliminada", { timeout: 2000 });
				this.getVehicle();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar la marca", { timeout: 2000 });
				this.loading = false;
			}
		},

		async createVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.createVehicle(this.item_aux);
				this.$toast.success("Marca creada", { timeout: 2000 });
				this.getVehicle();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear la marca", { timeout: 2000 });
				this.loading = false;
			}
		},

		async updateVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.updateVehicle(this.item_aux);
				this.$toast.success("Marca actualizada", { timeout: 2000 });
				this.getVehicle();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar la marca", { timeout: 2000 });
				this.loading = false;
			}
		},

    exportarExcel() {
      let data = XLSX.utils.json_to_sheet(this.items)
      const workbook = XLSX.utils.book_new()
      const filename = 'Tipos_vehiculos'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

		modificar_item(item){
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isEdit = true;
			this.isDelete = false;

		},

		crear_item(){
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;

		},
		
		close_dialog_edit() {
			try {
				if(!this.isDelete) this.$refs.formDialogEdit.reset();
				this.dialog_edit = false;
				this.isEdit = false;
				this.isDelete = false;
				this.item_aux = {
					id: '',
					name: '',
				};
			} catch (error) {
				console.log(error);
			}
		},

		acept_edit() {
			if(this.isEdit){
				console.log("editando", this.item_aux);
				this.updateVehicle();
				this.close_dialog_edit();
			}else{
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.createVehicle();
				this.close_dialog_edit();
			}
		},

		delete_item(item){
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		acept_delete(){
			this.deleteVehicle(this.item_aux);
			this.close_dialog_edit();
		}



   


  },
};
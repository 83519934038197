import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import Axios from "axios";
import logo from "../../assets/Q_Logo_BordeBlanco.png";
import barnerLogo from "../../assets/QuetzalShuttle_Logo_BordeBlanco.png";



//INCRIPTACION
import CryptoJS from "crypto-js";
import { AgGridVue } from "ag-grid-vue";

export default {

  components: {
    AgGridVue,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  name: "Login",



  data() {
    return {
      logo,
      loading: false,
      barnerLogo,
      login: {
        email: "",
        password: ""
      },
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    };
  },
  validations() {
    return {
      login: {
        email: {
          required,
          // email
          RegExp: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        },
        password: {
          required,
          // min: minLength(8)
        }
      },
    }
  },
  methods: {
    async auth() {
      sessionStorage.removeItem("CARD_INFO");
      sessionStorage.removeItem("CARD_REQUEST");
      this.loading = true;
      try {

        let r = await this.$store.state.services.loginService.postLogin(this.login);
        if (r.status === 200) {
          this.$toast.success("Bienvenido")
          console.log("r",r);
          let {role, driver_id ,token} = r.data
          
          console.log("data");
          console.log(role, token);
          console.log(process.env.VUE_APP_NAME_KEY_SESSION)
          Axios.defaults.headers.common.Authorization = `Bearer ${token}`
          Axios.defaults.headers.common.Accept = 'application/json'
          sessionStorage.setItem(process.env.VUE_APP_NAME_SESSION, CryptoJS.AES.encrypt( JSON.stringify({role,token,driver_id}), process.env.VUE_APP_NAME_KEY_SESSION));
          this.loading = false;
          console.log("role",role , role == 2);
          if(role == 2){
            this.$router.push({ path: "/administrador" });
          }else if(role == 3){
            this.$router.push({ path: "/empleado" });
          }else if(role == 4){
            this.$router.push({ path: "/client" });
          }
        }else{
          this.loading = false;
          this.$toast.error("Usuario y/o Contraseña incorrectos")
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("Usuario y/o Contraseña incorrectos")
      }
    }
  },
};
import { render, staticRenderFns } from "./brand_vehicle.vue?vue&type=template&id=3a1fe8d2"
import script from "./brand_vehicle.js?vue&type=script&lang=js&external"
export * from "./brand_vehicle.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <md-content md-theme="selection">
    <router-view></router-view>
  </md-content>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    //console.log("url usada", process.env.VUE_APP_SERVICE_URL);
  },
};
</script>

<style lang="scss">
.text-gold {
  color: #f1a300;
}

@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("bottom-bar-gold",
  (primary: #f1a300,
  ));

@include md-register-theme("selection",
  (accent: #f1a300,
  ));

@import "~vue-material/dist/theme/all"; // Apply the theme

@font-face {
  font-family: "Ubuntu-B";
  src: local("Ubuntu-B"), url("./assets/fonts/Ubuntu-B.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-L";
  src: local("Ubuntu-L"), url("./assets/fonts/Ubuntu-L.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-M";
  src: local("Ubuntu-M"), url("./assets/fonts/Ubuntu-M.ttf") format("truetype");
}

.v-menu__content {
  border-radius: 16px !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.theme--light.v-stepper {
  // background: #2a3d2e2e;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}


.time-picker::after,
.time-picker::before {
  border-bottom: 1px solid #949494 !important;
  border-top: none !important;
  width: 100% !important;
  max-width: none !important;
}

.time-picker {
    width: 100% !important;
    max-width: none !important;
}

.vue__time-picker input.display-time {
  border: none !important;
}

.vue-tel-input {
    border-radius: 3px;
    display: flex;
    border: none !important;
    border-bottom: 1px solid #949494 !important;
    text-align: left;
}
</style>
import barnerLogo from "../../assets/QuetzalShuttle_Logo_FndTransparente.png";

export default {


  name: "Register",
  data() {
    return {
      barnerLogo,
      dialogDocs: false,
      src: null,
      titulo: '',
      loading: false,
      departamentos: [],
      municipios: [],
      isFormValid: true,
      register: {
        confirmar_correo: "",
        name: "",
        last_name: "",
        email: "",
        confirmPassword: "",
        password: ""
      },

      showPass:false,
      showPassC: false,

      password_nueva: {
        a: (v) => !!v || "Campo obligatorio",
        b: (v) => (v && v.length >= 9) || 'La contraseña debe tener minímo 9 caracteres',
        c: (v) => /[0-9]+/.test(v) || 'Debe contener al menos un número',
        d: (v) => /[!-/]+|@+/.test(v) || 'Debe contener al menos un 1 caracter especial',
      },

      confirmarRules: {
        a: (v) => !!v || "Campo obligatorio",
        b: (v) => (v && v.length >= 9) || 'La contraseña debe tener minímo 9 caracteres',
        c: (v) => (v === this.register.password) || 'Las conttraseñas no coinciden'
      },

      rules_campo_obligatorio: [
        (v) => !!v || "Campo obligatorio"
      ],
      
      emailRules: [
        v => !!v || 'Campo obligatorio',
        v => /.+@.+/.test(v) || 'El correo no es válido',
      ],

      emailRulesC: [
        v => !!v || 'Campo obligatorio',
        v => /.+@.+/.test(v) || 'El correo no es válido',
        v => (v === this.register.email) || 'Los correos no coinciden'
      ],

      show:false,
    };
  },



  watch: {},

  async created() {
  },

  methods: {

    clear() {
      this.register = {
        name: "",
        last_name: "",
        email: "",
        confirmPassword: "",
        password: "",
      }
    },
    async registrar() {
      //console.log(this.v$.register.$touch());
   
      if (this.$refs.formRegistro.validate()) {
        this.loading = true;
        console.log("usuario registrado");
        console.log(this.register);
        try {
          let r = await this.$store.state.services.administradorService.createUser(this.register)
          console.log(r);
          if (r.data.data === false) {
            this.$toast.error(r.data.message, { timeout: 3000, position: "top-center" })
            this.loading = false;
          } else {
            this.$toast.success("", { timeout: 8000, position: "top-center" })
            this.$router.push("/login");
          }
        } catch (error) {
          //console.log(error);
          this.$toast.error("Error al crear el usuario, comunicate con soporte", { timeout: 3000, position: "top-center" })
          this.loading = false;
        }
      }
    },

    inicio() {
      let home = sessionStorage.getItem("home");
      this.$router.push(home);
    }
  }
};
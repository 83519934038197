import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "Precio en Quetzales",
          align: "center",
          sortable: true,
          value: "price",
        },
				{
          text: "Distancia",
          align: "center",
          sortable: true,
          value: "distance",
        },
				{
          text: "Medida de longitud",
          align: "center",
          sortable: true,
          value: "measure_type.description",
        },
				{
					text: "Abreviatura",
					align: "center",
					sortable: true,
					value: "measure_type.abbreviation",
				},
				{
					text: "Precio total en Quetzales",
					align: "center",
					sortable: true,
					value: "total",
				},
				{
					text: "Origen",
					align: "center",
					sortable: true,
					value: "origin.place",
				},
				{
					text: "Destino",
					align: "center",
					sortable: true,
					value: "destination.place",
				},
				{
					text: "Precio por defecto",
					align: "center",
					sortable: true,
					value: "price_default",
				},
				{
					text:"Max de pasajeros",
					align: "center",
					sortable: true,
					value: "people",
				},
				{
					text: "Aplica pago en efectivo",
					align: "center",
					sortable: true,
					value: "only_cash",
				},
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],

			items: [],

			item_aux: {
				id: -1,
				amount: '',
				currency_id: -1,
			},
			dialog_edit: false,
			isEdit: false,
			isDelete: false,
			valid: true,
			measures: [],
			destinations: [],
			origins: [],
    };
  },
  async created() {
		// crea una promesa para obtener los datos de los tipos de vehiculos
		let r1 =await this.getFee();	
		let r2 = await this.getMeasure_type();
		let r3 = await this.get_all_destinations();
		let r4 = await this.get_all_origins();


		// crea la promesa para obtener los datos de los tipos de vehiculos
		await Promise.all([r1, r2,r3,r4]);

  },

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},
	},
	
  methods: {

		async get_all_destinations() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_destination();
				this.destinations = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los destinos", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_all_origins() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_origin();
				this.origins = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los origenes", { timeout: 2000 });
				this.loading = false;
			}
		},


		async getMeasure_type() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getMeasure_type();
				this.measures = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los tipos de medida", { timeout: 2000 });
				this.loading = false;
			}
		}, 

		async getFee() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getFee();
				this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener las tarifas", { timeout: 2000 });
				this.loading = false;
			}
		}, 

		async deleteFee(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.deleteFee(item.id);
				this.$toast.success("Tarifa eliminada", { timeout: 2000 });
				this.getFee();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar la tarifa", { timeout: 2000 });
				this.loading = false;
			}
		},

		async createFee() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.createFee(this.item_aux);
				this.$toast.success("Tarifa creada", { timeout: 2000 });
				this.getFee();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear la tarifa", { timeout: 2000 });
				this.loading = false;
			}
		},

		async updateFee() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.updateFee(this.item_aux);
				this.$toast.success("Tarifa actualizada", { timeout: 2000 });
				this.getFee();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar el tarifario", { timeout: 2000 });
				this.loading = false;
			}
		},

    exportarExcel() {
      let data = XLSX.utils.json_to_sheet(this.items)
      const workbook = XLSX.utils.book_new()
      const filename = 'tarifas'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

		modificar_item(item){
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isEdit = true;
			this.isDelete = false;

		},

		crear_item(){
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;

		},
		
		close_dialog_edit() {
			try {
				if(!this.isDelete) this.$refs.formDialogEdit.reset();
				this.dialog_edit = false;
				this.isEdit = false;
				this.isDelete = false;
				this.item_aux = {
					id: '',
					name: '',
				};
			} catch (error) {
				console.log(error);
			}
		},

		acept_edit() {
			if(this.isEdit){
				console.log("editando", this.item_aux);
				this.updateFee();
				this.close_dialog_edit();
			}else{
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.createFee();
				this.close_dialog_edit();
			}
		},

		delete_item(item){
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		acept_delete(){
			this.deleteFee(this.item_aux);
			this.close_dialog_edit();
		}



   


  },
};
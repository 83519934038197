
import dialog_tarjeta from "../../dialog/dialog_tarjeta/dialog_tarjeta.vue"
import * as XLSX from 'xlsx'
export default {

  props: ['loading', 'filas', 'columnas', 'nombre_tabla',
    'show_expand', 'tarjeta',
    'mensual', 'anual'],

  components: { dialog_tarjeta },

  data() {
    return {
      expanded: [],
      singleExpand: false,
      search: "",
      tipo: true, //compras y ventas true cierra anual false
      lodingData: false,

      mostrarDialogTarjeta: false,
      dialogMensualAnual: false,
      dialogAnual: false,

      data_fecha: {
        fecha_usuario: "",
        fecha: ""
      },

      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,

      item: "",
      facturas: [],
      totalFacturas: "",

      declaraciones: [],

      totales_facturas: 0,
      totales_retenciones: 0,
      totales_impuesto: 0,

      select: "",

      items: [],

      anio_auxiliar: '',

      loading_pdf: false,
    }
  },

  created() {
    let anio_auxiliar = new Date().getFullYear();
    // genera 10 años adelante y 10 años atras
    for (let i = 0; i < 10; i++) {
      this.items.push(anio_auxiliar + i);
      this.items.unshift(anio_auxiliar - i);
    }
  
  },


  computed: {
    size() {
      const size = { xs: "x-small", sm: 'small', md: 'medium', lg: 'large', xl: 'x-large' }[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {}
    }
  },

  watch: {
    tipo() {

      if (this.tipo) {

        this.data_fecha.fecha = '';
        this.data_fecha.fecha_usuario = '';
        this.totalFacturas = '';
        this.facturas = '';
        this.date = '';



      } else {
        // this.tipo_picker="year"

        this.data_fecha.fecha = '';
        this.data_fecha.fecha_usuario = '';
        this.totalFacturas = '';
        this.facturas = '';
        this.date = '';

      }
    },
  },



  methods: {

    async exportarPdfAnual(){

      console.log("anio auxliar");
      console.log(this.anio_auxiliar);
      console.log("item");
      console.log(this.item);
      try {
        this.loading_pdf = true;
        let r = await this.$store.state.services.reportesService.obtenerCierreAnual(this.anio_auxiliar,this.item.id);  
        console.log(r);
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = r.data.data
        link.setAttribute('download', `${this.item.cui}_${this.item.nombre}_CIERRE_ANUAL.pdf`)
        document.body.appendChild(link)
        link.click()
        this.loading_pdf = false;
      } catch (error) {
        console.log(error);
        this.loading_pdf = false;
        this.$toast.error("No es posible procesar la información");
      }

    },
    
    async exportarPdf(){
      console.log("sebas");
      console.log(this.data_fecha.fecha_usuario);
      console.log("item");
      console.log(this.item);
      try {
        this.loading_pdf = true;
        let r = await this.$store.state.services.reportesService.obtenerPdfComprasVentas(this.item.id, this.data_fecha.fecha_usuario.split("/")[0], this.data_fecha.fecha_usuario.split("/")[1]);  
        console.log(r);
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = r.data.data
        link.setAttribute('download', `${this.item.cui}_${this.item.nombre}_LTE.pdf`)
        document.body.appendChild(link)
        link.click()
        this.loading_pdf = false;
      } catch (error) {
        console.log(error);
        this.loading_pdf = false;
        this.$toast.error("No es posible procesar la información");
      }
    },

    convertirnumero(numero) {
      let dollarUSLocale = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
      return dollarUSLocale.format(Math.abs(numero))

    },

    exportarExcel(tipo) {
      let data = XLSX.utils.json_to_sheet(this.facturas)
      const workbook = XLSX.utils.book_new()
      let filename = ''
      if (tipo == 1) {
        filename = 'facturas_emitidas' + this.item.nit;
      } else if (tipo == 2) {
        filename = 'facturas_recibidas' + this.item.nit;
      }

      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    exportarExcelAnual() {
      let info = [];
      this.declaraciones.forEach(element => {

        let data = {
          MES: element.mes,
          AÑO: this.select,
          TOTAL_FACTURAS: element.total_factura,
          TOTAL_RETENCIONES: element.total_retencion,
          IMPUESTO_PAGADO: element.impuesto
        };
        info.push(data);
      });

      let data = XLSX.utils.json_to_sheet(info)
      const workbook = XLSX.utils.book_new()
      const filename = 'cierre_anual' + this.item.nit;
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    cargarTarjeta(card) {
      this.$emit('crearTarjeta', card);
      this.mostrarDialogTarjeta = false;
    },

    verDialogMensual(item) {
      console.log("hola");
      console.log(item);
      this.item = item;
      this.dialogMensualAnual = true;
      this.data_fecha.fecha = '';
      this.data_fecha.fecha_usuario = '';
      this.facturas = [];
      this.totalFacturas = 0;
    },

    verDialogAnual(item) {
      console.log("hola");
      console.log(item);
      this.item = item;
      this.select = '';
      this.anio_auxiliar = '';
      this.totales_facturas = 0;
      this.totales_impuesto = 0;
      this.totales_retenciones = 0;
      this.dialogAnual = true;
      this.declaraciones = [];
    },

    async getListadoDeclaracionesInicial() {
      if (this.tipo) {
        this.data_fecha.fecha_usuario = this.date.split("-")
          .reverse()
          .join("/");

        let fecha = this.data_fecha.fecha_usuario.split("/");

        this.data_fecha.fecha = this.date;


        try {
          this.lodingData = true;
          let r =
            await this.$store.state.services.presentacionService.postlibrosVentas({ fecha: fecha[0] + "/" + fecha[1], usuario_id: this.item.id });
          console.log("libros de ventas");
          console.log(r);

          this.facturas = r.data.data.facturas;

          let dollarUSLocale = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

          let total = r.data.data.total_facturas;

          this.totalFacturas = dollarUSLocale.format(total);
          this.lodingData = false;

          if (this.facturas == 0) {
            this.$toast.info("Sin movimiento")
            this.facturas = [];
          }


        } catch (error) {
          console.log(error);
          this.$toast.info(
            "No se encontraron coincidencias"
          );
          this.lodingData = false;

        }
      } else {
        this.data_fecha.fecha_usuario = this.date.split("-")
          .reverse()
          .join("/");

        let fecha = this.data_fecha.fecha_usuario.split("/");

        this.data_fecha.fecha = this.date;


        try {
          this.lodingData = true;
          let r =
            await this.$store.state.services.presentacionService.postlibrosCompras({ fecha: fecha[0] + "/" + fecha[1], usuario_id: this.item.id });
          console.log("libros de compras");
          console.log(r);

          this.facturas = r.data.data.facturas;

          let dollarUSLocale = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

          let total = r.data.data.total_facturas;

          this.totalFacturas = dollarUSLocale.format(total);
          this.lodingData = false;


        } catch (error) {
          console.log(error);
          this.$toast.info(
            "No se encontraron coincidencias"
          );
          this.lodingData = false;

        }
      }

    },

    onChange() {
      console.log(this.select);
      // this.values.push(this.select)
      this.$nextTick(() => {
        this.select = null
      })
    },

    async getReporteAnual() {
      try {
        console.log("aqui es");
        this.lodingData = true;
        this.anio_auxiliar = '';
        this.totales_facturas = 0;
        this.totales_impuesto = 0;
        this.totales_retenciones = 0;
        this.declaraciones = [];
        let r =
          await this.$store.state.services.presentacionService.postCierreAnual({ anio: this.select, usuario_id: this.item.id });
        // console.log("informe final");
        console.log(r);

        this.declaraciones = r.data.data;

        this.declaraciones.forEach(element => {
          this.totales_facturas = this.totales_facturas + element.total_factura
        });


        this.declaraciones.forEach(element => {
          this.totales_retenciones = this.totales_retenciones + element.total_retencion
        });


        this.declaraciones.forEach(element => {
          this.totales_impuesto = this.totales_impuesto + element.impuesto
        });

        this.lodingData = false;

        this.anio_auxiliar = this.select;
        // this.$nextTick(() => {
        //   this.select = null
        // })
        this.select= '';
      } catch (error) {
        console.log(error);
        this.$toast.info(
          "No se encontraron coincidencias"
        );
        this.lodingData = true;
      }
    }
  }
}
import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "center",
          sortable: true,
          value: "name",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],

			items: [],

			item_aux: {
				id: -1,
				name: '',
			},
			dialog_edit: false,
			isEdit: false,
			isDelete: false
    };
  },
  async created() {
		await this.getBrandVehicle();	
  },

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},
	},
	
  methods: {
		async getBrandVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getBrandVehicle();
				this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los usuarios", { timeout: 2000 });
				this.loading = false;
			}
		}, 

		async deteleBrandVehicle(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.deleteBrandVehicle(item.id);
				this.$toast.success("Marca eliminada", { timeout: 2000 });
				this.getBrandVehicle();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar la marca", { timeout: 2000 });
				this.loading = false;
			}
		},

		async createBrandVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.createBrandVehicle(this.item_aux);
				this.$toast.success("Marca creada", { timeout: 2000 });
				this.getBrandVehicle();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear la marca", { timeout: 2000 });
				this.loading = false;
			}
		},

		async updateBrandVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.updateBrandVehicle(this.item_aux);
				this.$toast.success("Marca actualizada", { timeout: 2000 });
				this.getBrandVehicle();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar la marca", { timeout: 2000 });
				this.loading = false;
			}
		},

    exportarExcel() {
      let data = XLSX.utils.json_to_sheet(this.items)
      const workbook = XLSX.utils.book_new()
      const filename = 'Marcas_vehiculos'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

		modificar_item(item){
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isEdit = true;
		},

		crear_item(){
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;
		},
		
		close_dialog_edit() {
			try {
				if(!this.isDelete) this.$refs.formDialogEdit.reset();
				this.dialog_edit = false;
				this.isEdit = false;
				this.isDelete = false;
				this.item_aux = {
					id: '',
					name: '',
				};
			} catch (error) {
				console.log(error);
			}
		},

		acept_edit() {
			if(this.isEdit){
				console.log("editando", this.item_aux);
				this.updateBrandVehicle();
				this.close_dialog_edit();
			}else{
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.createBrandVehicle();
				this.close_dialog_edit();
			}
		},

		delete_item(item){
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		acept_delete(){
			this.deteleBrandVehicle(this.item_aux);
			this.close_dialog_edit();
		}



   


  },
};
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[_c('v-main',[_c('div',{staticClass:"register"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-6 col-md-8 col-sm-12"},[_c('md-card',{staticClass:"md-elevation-3 p-3 rounded-card my-5 p-4"},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"p-0 rounded-circle m-3",attrs:{"src":require("@/assets/Q_Logo.jpg"),"width":"40%"}}),_c('v-alert',{attrs:{"border":"top","colored-border":"","type":"success","elevation":"2"}},[_vm._v(" Please enter your new password ")])],1),_c('div',{staticClass:"container"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.cambiarPass.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 ps-0"},[_c('md-field',{class:{
                          'mb-0 md-invalid':
                            _vm.v$.newPass.pass_nueva.$errors.length,
                        },attrs:{"md-has-password":""}},[_c('label',[_vm._v("Password")]),_c('md-input',{attrs:{"type":"password","placeholder":"* Password","maxlength":"30"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.auth.apply(null, arguments)}},model:{value:(_vm.v$.newPass.pass_nueva.$model),callback:function ($$v) {_vm.$set(_vm.v$.newPass.pass_nueva, "$model", $$v)},expression:"v$.newPass.pass_nueva.$model"}})],1),_vm._l((_vm.v$.newPass.pass_nueva.$errors),function(error,index){return _c('div',{key:index + 'c'},[_c('span',{staticClass:"md-error",staticStyle:{"color":"#ff1744"}},[_vm._v(_vm._s(error.$message))])])})],2),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 ps-0"},[_c('md-field',{class:{
                          'mb-0 md-invalid':
                            _vm.v$.newPass.confirmPassword.$errors.length,
                        },attrs:{"md-has-password":""}},[_c('label',[_vm._v("Confirm Password")]),_c('md-input',{attrs:{"type":"password","placeholder":"* Confirm Password","maxlength":"30"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.auth.apply(null, arguments)}},model:{value:(_vm.v$.newPass.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.v$.newPass.confirmPassword, "$model", $$v)},expression:"v$.newPass.confirmPassword.$model"}})],1),_vm._l((_vm.v$.newPass.confirmPassword
                          .$errors),function(error,index){return _c('div',{key:index + 'cc'},[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(error.$message))])])})],2)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"actions md-layout md-alignment-center-space-between"},[_c('md-button',{staticClass:"md-icon-button md-primary",on:{"click":function($event){return _vm.$router.push('/')}}},[_c('md-icon',[_vm._v("home")])],1),_c('md-button',{staticClass:"md-raised md-primary rounded-pill",attrs:{"id":"btn-newPass","disabled":_vm.v$.newPass.$invalid},on:{"click":_vm.cambiarPass}},[_c('span',{staticClass:"animation fade-in-right",attrs:{"id":"span-newPass"}},[_vm._v("Change Password ")]),_c('md-icon',[_vm._v("lock_reset")])],1)],1),(_vm.loading)?_c('div',{staticClass:"loading-overlay"},[_c('md-progress-spinner',{attrs:{"md-mode":"indeterminate","md-stroke":2}})],1):_vm._e()])])])])])],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
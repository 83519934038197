<template>
  <error text="403! NO TIENES ACCESO" />
</template>

<script>
import error from "../../components/shared/error/error.vue";
export default {
  components: {
    error,
  },
  // name: "403",
};
</script>
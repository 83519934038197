import check_img from "../../../assets/Detective-check-footprint.svg";
import logo_quetzal from "../../../assets/Q_Logo.jpg"
import credit_card from '../../../components/shared/credit_card/credit_card.vue'
/**
 * vuePhoneNumberInput
 */
import { MazPhoneNumberInput } from 'maz-ui'



export default {

  components: {
    credit_card,
    MazPhoneNumberInput
  },

  data() {
    return {
      check_img,
      valid_trip: false,
      valid_info_pickup: false,
      valid_info_personal: false,
      loading: false,
			logo_quetzal,
      dialog_confirm: false,
      dialog_success: false,
      menu: false,
      confirmar: {
        token: "",
      },

      exchanges: [],

      reserve:
      {
        date: "",
        hour: "",
        childs: 0,
        passenger: "",
        name: "",
        last_name: "",
        phone: "",
        fee:{
          measure_type: 
          {
            abbreviation: "",
          },
          price: 0,
          total: 0
        },
        cui_passport: "",
        email: "",    
      },

      e6: 1,

      fees: [],
      min_date: new Date().toISOString().substr(0, 10),

      type_vehicles: [],
    };
  },

  async created() {
    // await this.verificar_contrasena();
		// obten los parametros
		let {data} = this.$route.params;

    // decodifica data de base64 a json
    let data_decode = atob(data);
    // convierte el json a objeto
    let data_json = JSON.parse(data_decode);
    console.log(data_json);
    // asigna los parametros a la variable de data
    // valida que date sea una fecha mayor a la actual
    if (data_json.fecha < this.min_date) {
      console.log("la fecha es menor a la actual", data_json.fecha , this.min_date);
      this.reserve.date = this.min_date;
    }else
    {
      this.reserve.date = data_json.fecha;
    }

    this.reserve.hour = data_json.hora;
    this.reserve.passenger = data_json.pasajeros;
    this.reserve.childs = data_json.pasajerosN;
    this.reserve.suitcase = data_json.noMaletas;
    this.reserve.flight_number = data_json.textoVuelo;
    this.reserve.children_chair = data_json.switchSillaNinos;
    this.reserve.wheelchair = data_json.switchSillaRuedas;


		
  },

  methods:
  {

    async getExchangeRate() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getExchangeRate();
				this.exchanges = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los tipos de cambio", { timeout: 2000 });
				this.loading = false;
			}
		}, 

    async getFee() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getFee();
				this.fees = r.data;
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener las tarifas", { timeout: 2000 });
				this.loading = false;
			}
		}, 

    async verificar_contrasena() {
      this.confirmar.token = this.$route.params.token;
      this.loading = true;
      try {
        let r = await this.$store.state.services.administradorService.verifiedEmail(this.confirmar);
        console.log(r);
        if (r.status == 200) {
          await this.$toast.info(r.data.message, { timeout: 5000 })
          this.loading = false;
          // creaa un timeout para redireccionar a la pagina de login
          setTimeout(() => {
            this.$router.push("/")
          }, 5000);
        } else {
          this.$toast.error("Error en la verificacion de correo electronico")
          this.loading = false;
        }
      } catch (error) {
        this.$toast.error("Error en la verificacion de correo electronico")
        this.loading = false;
        console.log(error);
      }

    },

    async reservar() {
      console.log(this.reserve);

      try {
        this.dialog_confirm = false;
        this.loading = true;
        let r = await this.$store.state.services.administradorService.create_reservation(this.reserve);
        console.log(r);
        if (r.status == 201) {
          this.dialog_success = true;
          await this.$toast.info("Your reserve was sent", { timeout: 5000 })
          this.loading = false;
          // creaa un timeout para redireccionar a la pagina de login
          setTimeout(() => {
            this.$router.push("/")
          }, 5000);
        } else {
          this.$toast.error("Error en la verificacion de correo electronico")
          this.loading = false;
        }
        
      } catch (error) {
        console.log(error);
        this.$toast.error("Not is posible to do reserve", { timeout: 2000 });
        this.loading = false;
      }     
    },

    async getTypeVehicle() {
      try {
        this.loading = true;
        let r = await this.$store.state.services.administradorService.getTypeVehicle();
        this.type_vehicles = r.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al obtener los tipos de vehiculos", { timeout: 2000 });
        this.loading = false;
      }
    }

  },
}
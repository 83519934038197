export default {
	data: () => ({
		loading: false,
		items: [],
		destinations: [],
		search: '',
		headers_origins: [
			{ text: 'País', value: 'name', align: 'start', sortable: true },

			{ text: 'Acciones', value: 'actions', sortable: false },
		],
		item_aux: {},
		isEdit: false,
		isDelete: false,
		dialog_edit: false,
		countries: [],
		valid: true,

	}),

	async created() {

		let r1 = await this.get_all_country();

		await Promise.all([r1]);
	},

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},
	},

	methods: {

		close_dialog_edit() {
			try {
				if (!this.isDelete) this.$refs.formDialogEdit.reset();
				this.dialog_edit = false;
				
				this.item_aux = {
					id: '',
					name: '',
				};
			} catch (error) {
				console.log(error);
			}
		},

		modificar_item(item) {
			this.item_aux = Object.assign({}, item);
			console.log(this.item_aux);

			this.dialog_edit = true;
			this.isEdit = true;
			this.isDelete = false;
		},

		exportarExcel() {
			let data = XLSX.utils.json_to_sheet(this.origins)
			const workbook = XLSX.utils.book_new()
			const filename = 'Países'
			XLSX.utils.book_append_sheet(workbook, data, filename)
			XLSX.writeFile(workbook, `${filename}.xlsx`)
		},

		delete_item(item) {
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		crear_item() {
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;
		},

		acept_edit() {
			if (this.isEdit) {
				console.log("editando", this.item_aux);
				this.update_country();
				this.close_dialog_edit();
			} else {
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.create_country();
				this.close_dialog_edit();
			}
		},

		acept_delete() {
			this.delete_country(this.item_aux);
			this.close_dialog_edit();
		},

		async delete_country(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.delete_country(item.id);

				if (r.status != 200) throw new Error(r);

				this.$toast.success("País eliminado", { timeout: 2000 });
				this.get_all_country();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar el País", { timeout: 2000 });
				this.loading = false;
				this.close_dialog_edit();
			}
		},

		async get_all_department() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_department();
				this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los Países", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_all_country() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_country();
				this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los paises", { timeout: 2000 });
				this.loading = false;
			}
		},

		async create_country() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.post_country(this.item_aux);
				this.$toast.success("País creado", { timeout: 2000 });
				this.get_all_country();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear el País", { timeout: 2000 });
				this.loading = false;
			}
		},

		async update_country() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.put_country(this.item_aux);
				this.$toast.success("País actualizado", { timeout: 2000 });
				this.get_all_country();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar el País", { timeout: 2000 });
				this.loading = false;
			}
		},

	}

}
class registerService {//servicios para login
    axios
    baseUrl

    constructor(axios, baseUrl) {
        this.axios = axios;
        this.baseUrl = `${baseUrl}`;
    }

    departamentos() {
        return this.axios.get(`${this.baseUrl}/departamento`)
    }

    postRegistro(body) {//POST validación de credenciales
        const url = `${this.baseUrl}/registro`
        return this.axios.post(url.toString(), body);
    }

}

export default registerService;
import check_img from "../../../assets/Detective-check-footprint.svg";
import logo_quetzal from "../../../assets/Q_Logo.jpg"
import credit_card from '../../../components/shared/credit_card/credit_card.vue'
import CryptoJS from "crypto-js";
/**
 * vuePhoneNumberInput
 */
import { MazPhoneNumberInput } from 'maz-ui'


export default {

  components: {
    credit_card,
    MazPhoneNumberInput
  },

  data() {
    return {
      check_img,
      valid_trip: false,
      valid_info_pickup: false,
      valid_info_personal: false,
      loading: false,
      logo_quetzal,
      dialog_confirm: false,
      dialog_success: false,
      menu: false,

      confirmar: {
        token: "",
      },
      min_date: new Date().toISOString().substr(0, 10),

      exchanges: [],

      reserve:
      {
        date: "",
        hour: "",
        passenger: "",
        name: "",
        last_name: "",
        phone: "",
        fee: {
          measure_type:
          {
            abbreviation: "",
          },
          price: 0,
          total: 0
        },
        cui_passport: "",
        email: "",
      },

      e6: 1,

      fees: [],
      type_vehicles: [],




    };
  },

  async created() {
    // await this.verificar_contrasena();
    // obten los parametros

    let informacion_incriptada = sessionStorage.getItem(process.env.VUE_APP_NAME_SESSION);
    if (informacion_incriptada != null) {
      let bytes = CryptoJS.AES.decrypt(informacion_incriptada, process.env.VUE_APP_NAME_KEY_SESSION);
      //Casteo de información a estring
      let informacion_desincriptada = bytes.toString(CryptoJS.enc.Utf8);
      console.log(informacion_desincriptada, "informacion_desincriptada");

      //Casteo de JSON String a Object json
      let r = JSON.parse(informacion_desincriptada)
      this.usuario_rol = r.role;

      if (r.token !== null) {

        let token = r.token;
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        console.log(jsonPayload);
        let personal_info = JSON.parse(jsonPayload);
        this.reserve.name = personal_info.name.split(" ")[0];
        this.reserve.last_name = personal_info.name.split(" ")[1];
        this.reserve.email = personal_info.email;
        this.reserve.cui_passport = personal_info.cui_passport;
        this.reserve.phone = personal_info.phone;
      }
    }

    this.getFee();
    this.getExchangeRate();
    this.getTypeVehicle();

    console.log("->>>>>>>>>>>>>>>>>>>>>>>>>");

  },

  methods:
  {

    async getExchangeRate() {
      try {
        this.loading = true;
        let r = await this.$store.state.services.administradorService.getExchangeRate();
        this.exchanges = r.data;
        console.log(r.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al obtener los tipos de cambio", { timeout: 2000 });
        this.loading = false;
      }
    },

    async getFee() {
      try {
        this.loading = true;
        let r = await this.$store.state.services.administradorService.getFee();
        this.fees = r.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al obtener las tarifas", { timeout: 2000 });
        this.loading = false;
      }
    },

    async reservar() {
      console.log(this.reserve);

      try {
        this.dialog_confirm = false;
        this.loading = true;
        let r = await this.$store.state.services.administradorService.create_reservation(this.reserve);
        console.log(r);
        if (r.status == 201) {
          this.dialog_success = true;
          await this.$toast.info("Your reserve was sent", { timeout: 5000 })
          this.loading = false;
          // creaa un timeout para redireccionar a la pagina de login
          setTimeout(() => {
            this.$router.push("/reservation_user")
          }, 5000);
        } else {
          this.$toast.error("Error en la verificacion de correo electronico")
          this.loading = false;
        }

      } catch (error) {
        console.log(error);
        this.$toast.error("Not is posible to do reserve", { timeout: 2000 });
      }
    },

    async getTypeVehicle() {
      try {
        this.loading = true;
        let r = await this.$store.state.services.administradorService.getTypeVehicle();
        this.type_vehicles = r.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al obtener los tipos de vehiculos", { timeout: 2000 });
        this.loading = false;
      }
    }

  },
}
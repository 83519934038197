<template>
  <div class="container">
    <!-- <template> -->
    <div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 col-sm-12">
          <img
            class="pb-3 rounded-circle"
            :src="img_404"
            width="100%"
          />
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>

    <h1 class="text-center py-5 font ">
      <strong>{{ text }}</strong>
    </h1>
    <!-- CREA UN BOTON VERDE CON BORDES REDONDOS QUE REGRESE AL LOGIN -->
    <div class="text-center">
        <button
          class="btn rounded-pill px-5 py-2"
          style="font-size: 1.5rem; background-color: #116009; color: white;"
          @click="$router.push('/')"
        >
          Go back!
        </button>
    </div>
    <!-- </template> -->
  </div>
</template>

<script>

import img_404 from "../../../assets/404-V3.svg";
export default {
  props: ["text"],
  name: "401",

  data() {
    return {
      img_404,
    };
  },
};
</script>

<style>
</style>
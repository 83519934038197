import * as XLSX from 'xlsx'
export default {
	data() {
		return {
			e6: -1,
			loading: false,
			search: "",
			headers: [
				{
					text: "# de Reserva",
					align: "center",
					sortable: true,
					value: "token",
				},
				{
					text: "Nombre de Usuario",
					align: "center",
					sortable: true,
					value: "complete_name",
				},
				{
					text: "Info General",
					align: "center",
					sortable: true,
					value: "OptionsReservations",
				},
				{
					text: "Hora de Recogida",
					align: "center",
					sortable: true,
					value: "pick_up_time",
				},
				{
					text: "Fecha de Recogida",

					sortable: true,
					value: "pick_up_date",
				},

				{
					text: "Origen",
					align: "end",
					sortable: true,
					value: "pick_up_place",
				},
				{
					text: "Destino",
					align: "start",
					sortable: true,
					value: "drop_off_place",
				},
				{
					text: "Conductor",
					align: "center",
					sortable: true,
					value: "Driver.name",
				},
				{
					text: "Vehiculo",
					align: "center",
					sortable: true,
					value: "Vehicle",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
				},
			],

			items: [],

			item_aux:
			{
				user: {
					name: "",
					last_name: "",
					email: "",
					cui_passport: "",
				},
				no_passengers: 0,
				pick_up_time: "",
				pick_up_date: "",
				reservation_number: 0,
				reservation_status_id: 0,
				reservation_detail: [
					{
						fee: {
							measure_type: {
								abbreviation: "",
							},
						}
					}
				],

				origin: {
					place: ""
				},
				destination: {
					place: ""
				},
				driver: {
					user: {
						name: "",
						last_name: "",
					},
					image: ""
				},
			},
			dialog_edit: false,
			dialog_detalles: false,
			dialog_aprobar: false,
			isEdit: false,
			isDelete: false,
			show1: false,
			roles: [
				{ num: 2, name: 'Administrador' },
				{ num: 3, name: 'Conductor' },
				{ num: 4, name: 'Cliente' },
				{ num: 5, name: 'Colaborador' },
			],
			valid: true,
			vehicles: [],
			drivers: [],
			fees: [],
			exchanges: [
				{
					amount: 0,
				}
			],
			date: null,
			modal_picker:false,
			date_txt: "",
			statud_id_selected: 1,
			tab:"tab-1",
			text: "Copiar",
			copyText: 'Copiar',
		};
	},
	async created() {
		// crea una promesa para obtener las marcas y los tipos
		// let p1 = this.getUser();
		// let p2 = this.get_all_reservation(1);
		// await Promise.all([p1, p2]);
		this.statud_id_selected = 1;
		this.tab = "tab-"+ this.statud_id_selected;
		this.loading = false;
	},

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},

		date(val) {
			this.date_txt = val ? val.toString().split("-").reverse().join("/") : ''

			if(this.date_txt){
				this.get_all_reservation(this.statud_id_selected,this.date);
			}
		}
	},

	methods: {

		async copyCode(item) {
			let textToCopy = 
			`# de Reserva: ${item.token};\n
			Nombre de Usuario: ${item.complete_name};\n
			# de Pasajeros: ${
				parseInt(item.OptionsReservations[3]?.description) +
				parseInt(item.OptionsReservations[4]?.description) };\n
			Hora de Recogida: ${item.pick_up_time};\n
			Fecha de Recogida: ${item.pick_up_date};\n
			Origen: ${item.pick_up_place};\n
			Destino: ${item.drop_off_place};\n
			Conductor: ${item.Driver.name};\n
			Vehiculo: ${item.Vehicle.plates};\n
			`
			await navigator.clipboard.writeText(textToCopy);
			this.copyText = "Copiado"
			setTimeout(() => {
				this.copyText = "Copiar"
			}, 2000);
		  },	  

		async getUser() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getUser();
				// this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los usuarios", { timeout: 2000 });
				this.loading = false;
			}
		},

		async cancelReservationStatus(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.cancelReservationStatus({token:item.token});
				this.$toast.success("Reservación Cancelada", { timeout: 2000 });
				this.get_all_reservation(5,this.date);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al cancelar la reservación", { timeout: 2000 });
				this.loading = false;
			}
		},

		async createUser() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.createUser(this.item_aux);
				this.$toast.success("Usuario creado", { timeout: 2000 });
				this.getUser();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear el usuario", { timeout: 2000 });
				this.loading = false;
			}
		},

		async updateReservationDetail(body) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.updateReservationDetail(body);
				this.$toast.success("La reservación ahora tiene un conductor y vehiculo asignado!", { timeout: 2000 });
				this.get_all_reservation(2,this.date);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar al usuario", { timeout: 2000 });
				this.loading = false;
			}
		},

		exportarExcel() {
			let data = XLSX.utils.json_to_sheet(this.items)
			const workbook = XLSX.utils.book_new()
			const filename = 'Tipos_vehiculos'
			XLSX.utils.book_append_sheet(workbook, data, filename)
			XLSX.writeFile(workbook, `${filename}.xlsx`)
		},

		async modificar_item(item) {
			// crea una promesa para obtener las marcas y los tipos
			let p1 = this.get_drivers_available();
			let p2 = this.get_vehicle_available();
			await Promise.all([p1, p2]);


			this.item_aux = Object.assign({}, item);
			console.log(this.item_aux);
			this.dialog_edit = true;
			this.isEdit = true;
			this.isDelete = false;

		},

		aprobar_reserva(item) {
			this.item_aux = Object.assign({}, item);
			this.dialog_aprobar = true;
		},

		crear_item() {
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;
		},

		close_dialog_edit() {
			try {
				if (!this.isDelete) this.$refs.formDialogEdit.resetValidation();
				this.dialog_edit = false;
				this.isEdit = false;
				this.isDelete = false;
				// this.item_aux = {
				// 	id: '',
				// 	name: '',
				// };
			} catch (error) {
				console.log(error);
			}
		},

		acept_edit() {
			if (this.isEdit) {
				console.log("editando", this.item_aux);
				let body = {
					token: this.item_aux.token,
					driver_id: this.item_aux.Driver.id,
					vehicle_id: this.item_aux.Vehicle.id,
				};
				this.updateReservationDetail(body);
				this.close_dialog_edit();
			} else {
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.createUser();
				this.close_dialog_edit();
			}
		},

		delete_item(item) {
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		acept_delete() {
			this.cancelReservationStatus(this.item_aux);
			this.close_dialog_edit();
		},

		async ver_detalle(item) {
			const routeData = this.$router.resolve({ path: '/reserve-tracking/'+ item.token  });
			console.log(routeData);
			window.open(routeData.href, '_blank');
		},

		async get_all_reservation(id,date) {
			try {

				this.statud_id_selected = id;
				this.tab = "tab-"+ this.statud_id_selected;
				// si date es null mostrar toast de seleccionar fecha
				if (date == null) {
					this.$toast.info("Seleccione una fecha", { timeout: 2000 , position: 'top-right'});
					return;
				}


				let r = await this.$store.state.services.administradorService.get_all_reservation(id,date);
				console.log("todas las reservaciones", r);
				if (r.status == 200) {
					r.data.data.filter((item) => {
						item.complete_name = item.PersonalInfo.first_name + " " + item.PersonalInfo.last_name;

						if(item.Driver == null){
							item.Driver = {
								id: 0,
							}
						}

						if(item.Vehicle == null){
							item.Vehicle = {
								id: 0,
							}
						}
					});

					this.items = r.data.data;
					console.log("este son los items", this.items);
				} else {
					this.$toast.error("Error al obtener las reservas", { timeout: 2000 });
				}

			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener las reservas", { timeout: 2000 });
			}
		},


		async get_drivers_available() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_drivers_available(
					{date:this.date}
				);
				this.drivers = r.data.data;
				console.log(r.data.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los conductores", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_vehicle_available() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_vehicle_available({
					date:this.date
				});
				this.vehicles = r.data.data;
				console.log(r.data.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los vehiculos", { timeout: 2000 });
				this.loading = false;
			}
		},

		async update_reservation() {
			try {
				this.loading = true;
				this.item_aux.reservation_status_id = 2;
				let r = await this.$store.state.services.administradorService.update_reservation(this.item_aux);
				this.$toast.success("Reserva actualizada", { timeout: 2000 });
				this.loading = false;
				this.dialog_aprobar = false;
				this.get_all_reservation(1);
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar la reserva", { timeout: 2000 });
				this.loading = false;
			}
		},

		async doneReservation(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.finish_reservation_by_token({token:item.token});
				this.$toast.success("Reserva finalizada", { timeout: 2000 });
				this.loading = false;
				this.dialog_aprobar = false;
				this.get_all_reservation(3,this.date);
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al finalizar la reserva", { timeout: 2000 });
				this.loading = false;
			}
		}

	},

};
<template>
    <v-app>


        <v-app-bar height="75px" color="#106008" dark fixed>

            <v-app-bar-nav-icon class="mx-7">
                <v-img :src="require('@/assets/Q_Logo_FndTransparente.png')" max-height="70" width="70" contain />
            </v-app-bar-nav-icon>

            <v-toolbar-title style="margin-left: -26px;">
                <span :class="fontWeight" style="font-weight: 700; color:white">
                    Quetzal Shuttle
                </span>
            </v-toolbar-title>
            <!-- agrega un menú con opciones -->
            <v-spacer></v-spacer>

            <v-btn elevation="24" color="error" class="mx-2"  rounded @click="getToReservationPages()">
                    Book now!
                </v-btn>

            <v-btn elevation="24" outlined rounded id="menu-activator" color="white" style="color: black;">
                More info
                <v-icon right>mdi-menu-down</v-icon>

            </v-btn>
            <v-menu activator="#menu-activator" offset-y origin="center center" :rounded="'b-xl'"
                transition="scale-transition">
                <v-list>
                    <v-list-item v-for="(item, index) in items2" :key="index" :value="index" :href="item.href">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>


        </v-app-bar>

        <v-main class="pt-15 bg-cards">
            <br id="part1">
            <br>
            <div class="px-12 pt-5">
                <div class="d-flex justify-center">
                    <!-- agrega un boton que te lleve a la pagina de reservaciones -->
                    
                    <v-carousel cycle height="250px"  hide-delimiters :show-arrows="false">
                        <v-carousel-item v-for="(item, i) in items" :key="i">
                            <v-sheet height="100px">
                                <v-row style="background-color: #e2eaea;"  class="fill-height" align="center" justify="center">
                                    <v-col class="text-center">
                                        <v-img :src="item.src"  
                                        
                                        width="100%"
                                        height="250px"
                                        ></v-img>

                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                    
    
                </div>
          
            </div>
            <br id="part2">
            <!-- por cada elemento del menu a darle click que se mueva por medio de un indice dentro del dom segun cada titulo -->
            <div class="px-12">
                <p class="text-center" style="color: #0f8900; font-weight: 900; font-size: 2.8rem;">Fall in love with
                    Guatemala</p>
                <div class="mb-6">

                    <div class="text-center" :style="'color: ##9e9e9e; font-size:1.4rem; font-weight:400'">Visit with us
                        the magical places that
                        this country offers you</div>
                </div>


                <!-- genera tres cards -->
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card>
                            <v-img :src="require('@/assets/fondos/pana1-1024x683.jpeg')" contain>

                            </v-img>
                            <v-card-title
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">Atitlán</v-card-title>
                            <v-card-text>
                                Lake Atitlán and its towns offer a unique experience full of impressive landscapes,
                                Mayan culture and a deep connection with nature. Immerse yourself in the beauty of its
                                waters, explore the charming towns on the shores of the lake and admire the majesty of
                                the surrounding volcanoes. Lake Atitlán awaits you with its mysticism and rich history
                                to give you an unforgettable experience in the heart of Guatemala.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card>
                            <v-img :src="require('@/assets/fondos/semucchampey.jpeg.webp')" contain>


                            </v-img>
                            <v-card-title
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">Semuc
                                Champey</v-card-title>
                            <v-card-text>
                                Exploring the natural wonders of Semuc Champey: If you are a lover of
                                nature and adventure, you cannot miss an impressive set of natural pools and waterfalls
                                hidden in the middle of the jungle that will leave you breathless. Explore the caves,
                                swim in the crystal clear waters and enjoy a landscape that seems to come from a dream.
                                You can join organized tours or venture out on your own, always keeping in mind the
                                necessary precautions.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card>
                            <v-img :src="require('@/assets/fondos/tikal-f1-571x364.jpg')" contain>




                            </v-img>
                            <v-card-title
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">Tikal</v-card-title>
                            <v-card-text>
                                Inmerse yourself in the heart of the Mayan world: Tikal is one of the most important
                                archaeological sites in the Mayan world and a must-see destination in Guatemala. Explore
                                the impressive pyramids, temples and palaces that are hidden in the middle of the jungle
                                and discover the history and culture of the ancient Mayan civilization. Tikal is a
                                unique experience that will take you back in time and leave you with unforgettable
                                memories.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-5 px-12 py-7" style="background-color: #f1f1f1;">
                <!-- genera una descripcion de una imagen con titulo y la iamgen del lado derecho -->
                <v-row>
                    <v-col cols="12" md="6" class="d-flex align-center ">
                        <div>
                            <v-card-title style="color: #0f8900; font-weight: 900; font-size: 2.8rem;">Antigua
                                Guatemala</v-card-title>
                            <v-card-text>
                                It is much more than a colonial city. It is a place full of history, charm and adventure
                                waiting to be discovered. From its fascinating past to the most popular tours and nearby
                                towns, Antigua offers an unforgettable experience for travelers. Embark on a journey
                                through
                                time, admire the breathtaking views, explore places inspired by fantasy and immerse
                                yourself
                                in the authenticity of the Guatemalan villages. Antigua Guatemala awaits you with open
                                arms
                                to give you an unforgettable experience!
                            </v-card-text>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-img :src="require('@/assets/fondos/antigua1-1280x854.jpg')" height="400px">

                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <br id="part3">
            <br>
            <div class="mt-5">
                <!-- genera una card con una imagen en la parte de arriba y la drescripcion por debajo-->
                <v-row>

                    <div style="padding-inline: 15%;">
                        <v-img :src="require('@/assets/fondos/img-1345-2220x1665.jpeg')" contain>


                        </v-img>
                        <v-card-title style="color: #0f8900; font-weight: 900; font-size: 2.8rem;">About
                            US</v-card-title>

                        <v-card-text class="text-justify">
                            <strong> Quetzal Shuttle</strong> prides itself on offering quality and reliable
                            transportation
                            services in Guatemala. Our goal is to provide our customers with a comfortable and safe
                            experience while traveling to and from the airport and other destinations across the
                            country.

                            Our company was founded with the vision of providing efficient and customized transportation
                            solutions that are tailored to the individual needs of each client. Whether you're traveling
                            solo, in a group, or with your family, we have an extensive fleet of vehicles including
                            minibuses, SUVs, and sedans to make sure you find the right option for your requirements.

                            We have a team of professional and friendly drivers who are committed to providing
                            exceptional
                            service. They are trained in road safety and are familiar with the most convenient routes to
                            get
                            you to your destination efficiently and on time.

                            In addition to our dedication to providing quality transportation, we also care about the
                            comfort and satisfaction of our customers. Our online booking platform makes it easy to
                            conveniently schedule and secure your transfers. We accept online payments and send booking
                            confirmations by email, giving you peace of mind that your transportation will be insured.

                            At Quetzal Shuttle, we value the trust that our customers place in us by choosing us as
                            their
                            transportation provider. We strive to exceed your expectations and make your travel
                            experience
                            smooth and enjoyable.

                            We are always open to your comments and suggestions to improve our services. If you have any
                            questions or need more information, don't hesitate to contact us. We are here to provide you
                            with reliable and comfortable transportation so that you can fully enjoy your experience in
                            Guatemala.

                            Thank you for choosing Quetzal Shuttle as your transportation partner. We look forward to
                            serving you and making your travels a memorable and pleasant experience.
                        </v-card-text>
                    </div>

                </v-row>

            </div>
            <br id="part4">
            <br>
            <div style="background-color: #f1f1f1; padding-inline: 15%;" class=" text-center py-7">
                <span :style="'color: #0f8900; font-weight: 900; font-size: 2.8rem;'">Why choose us?</span>
                <br>
                <span :style="'color: ##9e9e9e; font-size:1.4rem; font-weight:400'">
                    <strong>At Quetzal Shuttle</strong>


                    , we strive to be the preferred transportation option in Guatemala. Here are some reasons why you
                    should
                    choose us for your transportations needs:
                </span>
                <br>
                <v-row class="d-flex justify-center">
                    <v-col cols="12" md="3">
                        <div>
                            <v-icon x-large
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">verified_user</v-icon>
                            <div style="color: #000000; margin: 0;  font-size: 1.5rem; font-weight: bold;">
                                Experience and Reliability
                            </div>
                            <v-card-text>
                                With years of experience in the transportation industry, you can trust us to
                                be on time and provide you with quality service
                            </v-card-text>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div>
                            <v-icon x-large
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">send</v-icon>
                            <div style="color: #000000; margin: 0;  font-size: 1.5rem; font-weight: bold;">
                                Versatile fleet
                            </div>
                            <v-card-text>
                                We have a large fleet of vehicles that adapt to
                                your needs. Whether you're traveling solo, in a group, or as a family, we have the right
                                vehicle option for you.
                            </v-card-text>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div>
                            <v-icon x-large
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">sentiment_satisfied</v-icon>
                            <div style="color: #000000; margin: 0;  font-size: 1.5rem; font-weight: bold;">
                                Professional Drivers
                            </div>
                            <v-card-text>
                                Our drivers are trained and friendly professionals who care about your safety and
                                comfort.


                            </v-card-text>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div>
                            <v-icon x-large
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">
                                devices</v-icon>
                            <div style="color: #000000; margin: 0;  font-size: 1.5rem; font-weight: bold;">
                                Easy Online Reservations
                            </div>
                            <v-card-text>
                                Our online reservation system simplifies the process of scheduling your transfer through
                                our
                                website.
                            </v-card-text>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="justify-center">
                    <v-col cols="12" md="4">
                        <div>
                            <v-icon x-large
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">support_agent</v-icon>
                            <div style="color: #000000; margin: 0;  font-size: 1.5rem; font-weight: bold;">
                                Customer Support
                            </div>
                            <v-card-text>
                                We care about giving you an exceptional experience from the moment you contact us. Our
                                customer service team is available to answer your questions, provide assistance and
                                ensure
                                that your needs are met in a timely and satisfactory manner.
                            </v-card-text>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div>
                            <v-icon x-large
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">star_rate</v-icon>
                            <div style="color: #000000; margin: 0;  font-size: 1.5rem; font-weight: bold;">
                                Commitment to Excellence

                            </div>
                            <v-card-text>
                                We strive to provide excellent service in every interaction with our customers. We care
                                about your satisfaction and we promise to exceed your expectations. We value your
                                comments
                                and suggestions, and we are constantly working to improve our services.


                            </v-card-text>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div>
                            <v-icon x-large
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">paid</v-icon>
                            <div style="color: #000000; margin: 0;  font-size: 1.5rem; font-weight: bold;">
                                Competitive prices

                            </div>
                            <v-card-text>
                                We offer the best rates for our services. We believe in honesty and transparency in our
                                pricing policy, with no hidden charges or unpleasant surprises. You will get excellent
                                value
                                for your money by choosing us as your transportation provider.


                            </v-card-text>
                        </div>
                    </v-col>

                </v-row>
            </div>
            <br id="part5">
            <br>
            <div class="mt-5 text-center" style="padding-inline: 15%;">
                <span style="color: #0f8900; font-weight: 900; font-size: 2.8rem;">Frequently Asked Questions</span>
                <br>
                <br>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel1">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            How can i book a tranfer with Quetzal Shuttle?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        You can book your transfer with
                                        <strong>
                                            Quetzal Shuttle
                                        </strong> through our website. Simply
                                        select the
                                        origin, destination, date and time of your trip, choose the vehicle and the
                                        number of
                                        people, and complete the reservation process.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel2">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            What are the accepted payment methods?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        We accept credit and debit card payments. Our online reservation system is
                                        integrated with a
                                        secure payment gateway that guarantees the protection of your data.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel3">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            What are the cancelation policies?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        Our cancellation policies vary according to the type of reservation and the time
                                        in advance
                                        with which the cancellation is made. You can find all the detailed information
                                        about our
                                        cancellation policies here.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel4">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            What kind of vehicles Quetzal Shuttle offer?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        <strong>
                                            Quetzal Shuttle
                                        </strong>
                                        offers a fleet of vehicles that includes minibuses from 7 to 15 passengers,
                                        4-passenger SUVs
                                        and sedans. Our vehicles are safe, comfortable and equipped with all the
                                        necessary amenities
                                        to guarantee a pleasant trip.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel5">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            Do you offer transportation services to and from the airport?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        Yes,
                                        <strong>
                                            Quetzal Shuttle
                                        </strong> offers transportation services to and from the airport. Our drivers
                                        will meet you
                                        at the airport with a personalized sign and will take you to your destination
                                        safely and
                                        efficiently.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel6">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            Can I request additional services, such as baby seats or additional stops?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        Yes, you can request additional services such as baby seats or additional stops
                                        during the
                                        online booking process. Simply select the appropriate options and we'll take
                                        care of your
                                        additional needs.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel7">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            How can I make a claim for a forgotten item?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        If you forgot any object in our vehicle, please contact us through our contact
                                        form or by
                                        email. Provide the description of the lost object and the details of your trip
                                        so that we
                                        can help you recover it.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel8">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            How far in advance should I reserve my transfer?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        We recommend you book your transfer as far in advance as possible to guarantee
                                        the
                                        availability of the vehicles and the desired times. However, we also accept
                                        reservations
                                        with a minimum of 24 hours in advance, subject to availability.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel9">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            What destinations does Quetzal Shuttle cover?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        <strong>
                                            Quetzal Shuttle
                                        </strong>
                                        offers transfers to various destinations throughout the country. Our coverage
                                        includes the
                                        main cities, tourist destinations and other places of interest in Guatemala. You
                                        can consult
                                        the complete list of destinations on our website.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-expansion-panels v-model="panel10">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="background-color: #f1f1f1;">

                                        <template v-slot:actions>
                                            <v-icon left style="color: #0f8900;" x-large>expand_circle_down</v-icon>
                                        </template>
                                        <div style="font-weight: bold; font-size: 1.4rem;">
                                            How can i track my reservation?
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        Once you have made your reservation, you will receive an email confirmation with
                                        the details
                                        of your trip. You can also access your account on our website and track your
                                        reservation.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-col>
                </v-row>

            </div>

            <br id="part6">
            <br>
            <div class="mt-5 text-center" style="padding-inline: 15%; background-color: #f1f1f1;">
                <div class="d-flex justify-center ">
                    <v-alert icon="mdi-shield-lock-outline rounded" prominent text type="success" class="mt-10">
                        <div style="background-color: white; border-radius: 50px; color: #000000; padding: 20px;">
                            <strong>
                                These service policies are designed to ensure a positive experience for our customers
                                and set clear
                                expectations. If you have any questions or need more information, do not hesitate to
                                contact us
                                through the contact channels provided.

                            </strong>
                        </div>
                    </v-alert>
                </div>
                <br>
                <div class="text-start">
                    <span style="color: #0f8900; font-weight: 900; font-size: 2.5rem;">Service Policies:</span>
                    <div>
                        At
                        <strong>
                            Quetzal Shuttle
                        </strong>
                        , we strive to offer a reliable, safe, and quality transportation service to all of our
                        customers. Our
                        service policies are detailed below to ensure a satisfactory experience:
                    </div>
                    <br>
                    <div>
                        <strong>
                            1. Reservations and Confirmations:
                        </strong>
                        <br>
                        <br>
                        • Reservations can be made through our website, by phone or in person. It is required to provide
                        accurate
                        information, such as the date, time and details of the transfer.
                        <br>
                        • Once we receive your reservation, we will send you an email confirmation with the details of
                        your
                        reservation. Please check the information carefully and contact us if there are any errors or
                        necessary
                        changes.
                    </div>
                    <br>
                    <div>
                        <strong>
                            2. Punctuality:
                        </strong>
                        <br>
                        <br>
                        • Our goal is to arrive promptly at the agreed location at the scheduled date and time. However,
                        please note
                        that delays may occur due to unforeseen traffic conditions or other circumstances beyond our
                        control.
                        <br>
                        • We recommend you to be ready and available for your transfer 15 minutes in advance.
                    </div>
                    <br>
                    <div>
                        <strong>
                            3. Professional drivers:
                        </strong>
                        <br>
                        <br>
                        • Our drivers are trained professionals with experience in the transportation service. They are
                        committed to
                        your safety and comfort during the trip.
                        <br>
                        • Maintain a respectful and courteous demeanor at all
                        times and be willing to answer any questions or concerns you may have.
                    </div>
                    <br>
                    <div>
                        <strong>
                            4. Cancellations and changes:
                        </strong>
                        <br>
                        <br>
                        • If you need to cancel or make changes to your reservation, we recommend doing so 24 hours
                        before the
                        scheduled date and time. Check our cancellation policy for detailed information on terms and
                        possible
                        charges.
                        <br>
                        • We reserve the right to cancel or modify a reservation in case of unforeseen circumstances or
                        situations
                        that put the safety of our clients and drivers at risk. In such cases, we will do our best to
                        offer suitable
                        alternatives.
                    </div>
                    <br>
                    <div>
                        <strong>
                            5. Liability:
                        </strong>
                        <br>
                        <br>
                        • We strive to ensure the safety of our clients and their belongings during the transfer.
                        However, we are
                        not responsible for loss, damage or theft of personal belongings left in our vehicles.

                        <br>
                        • Remember to take your valuables with you and make sure you don't leave anything behind when
                        leaving the
                        vehicle.


                    </div>
                    <br>
                    <div>
                        <strong>
                            6. Comments and claims:
                        </strong>
                        <br>
                        <br>
                        • We appreciate your comments and opinions about our services. If you have any concerns,
                        complaints or
                        suggestions, we encourage you to contact us. We will do our best to resolve any problem quickly
                        and to your
                        satisfaction.
                        <br>
                    </div>
                </div>
                <br>
                <br>
            </div>
            <br id="part9">
            <br>
            <!-- coloca un footer de conctact us con tres columnas  -->
            <div class="mt-5 text-center" style="padding-inline: 15%;">
                <span style="color: #0f8900; font-weight: 900; font-size: 2.8rem;">Contact Us</span>
                <br>
                <span :style="'color: ##9e9e9e; font-size:1.4rem; font-weight:400'">We are here to assist you with your
                    transportation needs</span>
                <br>
                <v-row>
                    <v-col cols="12" md="4">
                        <div>
                            <v-card-title
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">Phone
                                Number</v-card-title>
                            <v-card-text>
                                <v-icon>phone</v-icon>
                                <span style="font-size: 1.5rem;">+502 56926911</span>
                            </v-card-text>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div>
                            <v-card-title
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">Email
                                Address</v-card-title>
                            <v-card-text>
                                <v-icon>email</v-icon>
                                <span style="font-size: 1.5rem;">
                                    info@quetzalshuttle.com
                                </span>
                            </v-card-text>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div>
                            <v-card-title
                                style="color: #0f8900; margin: 0; line-height: 1.3; padding-bottom: 1.5rem;">Address</v-card-title>
                            <v-card-text>
                                <v-icon>location_on</v-icon>
                                <span style="font-size: 1.5rem;">
                                    Guatemala City
                                </span>
                            </v-card-text>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <vue-whatsapp-widget phone="+502 1234-5678" companyName="Quetzal Shuttle" style="background-color: white;"
                :messages='["Hi there 👋 How can I help you ?"]' :companyLogo="logo_quetzal"
                textReply="We contact with you" />
            <router-view></router-view>


        </v-main>
    </v-app>


    <!-- <router-view></router-view> -->
</template>

<script>
import router from '../../router';
import VueWhatsappWidget from 'vue-whatsapp-widget'

import check_img from "../../assets/Detective-check-footprint.svg";
import logo_quetzal from "../../assets/Q_Logo.jpg"
import credit_card from '../../components/shared/credit_card/credit_card.vue'
/**
 * vuePhoneNumberInput
 */
// import { MazPhoneNumberInput } from 'maz-ui'

export default {

    components: {
        credit_card,
        // MazPhoneNumberInput,
        VueWhatsappWidget
    },

    data: () => ({
        drawer: true,
        panel1: [0],
        panel2: [0],
        panel3: [0],
        panel4: [0],
        panel5: [0],
        panel6: [0],
        panel7: [0],
        panel8: [0],
        panel9: [0],
        panel10: [0],

        items2: [
            { title: 'Programa tu viaje / Schedule your trip', href: '#part1' },
            { title: 'Enamorate de Guatemala / Fall in love with Guatemala', href: '#part2' },
            { title: 'Acerca de Nosotros / About Us', href: '#part3' },
            { title: '¿Por qué Elegirnos? / Why choose us?', href: '#part4' },
            { title: 'Preguntas Frecuentes / Frequently Asked Questions', href: '#part5' },
            { title: 'Políticas de Servicio / Service Policies', href: '#part6' },
            { title: 'Términos y Condiciones / Terms and Conditions', href: '#part7' },
            { title: 'Políticas de Privacidad / Privacy Policies', href: '#part8' },
            { title: 'Contáctanos / Contact Us', href: '#part9' },
        ],
        check_img,
        valid_trip: false,
        valid_info_pickup: false,
        valid_info_personal: false,
        loading: false,
        logo_quetzal,
        dialog_confirm: false,
        dialog_success: false,
        menu: false,
        confirmar: {
            token: "",
        },

        exchanges: [],

        reserve:
        {
            date: "",
            hour: "",
            childs: 0,
            passenger: "",
            name: "",
            last_name: "",
            phone: "",
            fee: {
                measure_type:
                {
                    abbreviation: "",
                },
                price: 0,
                total: 0
            },
            cui_passport: "",
            email: "",
        },

        e6: 1,

        fees: [],
        min_date: new Date().toISOString().substr(0, 10),

        type_vehicles: [],

        items: [
            {
                src: require('@/assets/fondos/antigua1-1280x854.jpg'),
            },

            {
                src: require('@/assets/fondos/pana1-1024x683.jpeg'),
                color: 'white'
            }, {
                src: require('@/assets/fondos/semucchampey.jpeg.webp'),
                color: 'white'
            },
            {
                src: require('@/assets/QuetzalShuttle_Logo.jpg'),
                color: 'white'
            },

        ],
    }),

    async created() {

    },

    computed: {
        fontWeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 'h5'
                case 'sm': return 'h5'
                case 'md': return 'h3'
                case 'lg': return 'h2'
                case 'xl': return 'h1'
            }
        },

        imgDisplay() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 'cover'
                case 'sm': return 'cover'
                case 'md': return 'cover'
                case 'lg': return 'cover'
                case 'xl': return 'cover'
            }
        },
    },

    methods:
    {
        async verificar_contrasena() {
            this.confirmar.token = this.$route.params.token;
            this.loading = true;
            try {
                let r = await this.$store.state.services.administradorService.verifiedEmail(this.confirmar);
                console.log(r);
                if (r.status == 200) {
                    await this.$toast.info(r.data.message, { timeout: 5000 })
                    this.loading = false;
                    // creaa un timeout para redireccionar a la pagina de login
                    setTimeout(() => {
                        this.$router.push("/")
                    }, 5000);
                } else {
                    this.$toast.error("Error en la verificacion de correo electronico")
                    this.loading = false;
                }
            } catch (error) {
                this.$toast.error("Error en la verificacion de correo electronico")
                this.loading = false;
                console.log(error);
            }

        },

        async reservar() {
            try {
                this.dialog_confirm = false;
                this.loading = true;
                let r = await this.$store.state.services.administradorService.create_reservation(this.reserve);
                console.log(r);
                if (r.status == 201) {
                    this.dialog_success = true;
                    await this.$toast.info("Your reserve was sent", { timeout: 5000 })
                    this.loading = false;
                    // creaa un timeout para redireccionar a la pagina de login
                    setTimeout(() => {
                        this.$router.push("/")
                    }, 5000);
                } else {
                    this.$toast.error("Error en la verificacion de correo electronico")
                    this.loading = false;
                }

            } catch (error) {
                console.log(error);
                this.$toast.error("Not is posible to do reserve", { timeout: 2000 });
                this.loading = false;
            }
        },

        getToReservationPages() {
            this.$router.push("/reservations")
        },
    },
}
</script>

<style scoped>
#whatsapp-chat {
    background-color: white !important;
}

.bg-cards {
    /* From https://css.glass */
    /* From https://css.glass */
    background: #e2eaea;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16.7px);
    -webkit-backdrop-filter: blur(16.7px);

}
</style>
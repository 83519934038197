<template>
  <carrousel :bar="bar" :captions="captions" :images="images" />
</template>

<script>
import carrousel from "@/components/carrousel/carrousel.vue";
export default {
  components: {
    carrousel,
  },
  created() {
    // sessionStorage.setItem("home","/dge");
  },
  data: () => ({
    bar: [
      { id: 1, label: "Hola", icon: "done" },
      { id: 2, label: "Hola", icon: "info" },
      { id: 3, label: "Hola", icon: "favorite" },
      { id: 4, label: "Hola", icon: "feed" },
      // { id: 5, label: "Hola", icon: "feed" },
    ],
    images: [
      require("@/assets/fondos/fondo1.jpg"),
      require("@/assets/fondos/fondo2.jpg"),
      require("@/assets/fondos/fondo3.jpg"),
      require("@/assets/fondos/fondo4.jpg"),
      require("@/assets/fondos/fondo5.jpg"),
    ],
    captions: [
      {
        id: 1,
        alto: "d-flex align-items-center",
        ancho: "justify-content-end",
        columna: "3",
        margin: "4",
        // title: "APP-TODO-TIC.COM",
        text: "Queremos ser la empresa líder en <strong>soporte estratégico</strong> 24/7 como plataforma de información y gestión para <strong>satisfacer</strong> las <strong>necesidades</strong> de los usuarios en aspectos <strong>fiscales</strong>, <strong>contables, legales</strong> y de alcance comercial.",
      },
      {
        id: 2,
        alto: "d-flex align-items-start",
        ancho: "justify-content-start",
        columna: "3",
        margin: "0",
        // title: "APP-TODO-TIC.COM",
        text: "Somos una empresa <strong>orgullosamente</strong> Guatemalteca.",
      },
      {
        id: 3,
        alto: "d-flex align-items-start",
        ancho: "justify-content-center",
        columna: "4",
        margin: "5",
        // title: "APP-TODO-TIC.COM",
        text: "Proveemos <strong>soluciones digitales</strong>, amigables, <strong>ágiles</strong>, de acceso permanente, <strong>seguras</strong>, económicas y <strong>confiables</strong> para satisfacer sus necesidades.",
      },
      {
        id: 4,
        alto: "d-flex align-items-start",
        ancho: "justify-content-start",
        columna: "6",
        margin: "5",
        // title: "APP-TODO-TIC.COM",
        text: "Promovemos la <strong>honestidad</strong>, el <strong>respeto</strong> por los demás, la <strong>responsabilidad</strong>, el control emocional, la <strong>puntualidad</strong>, la lealtad y la <strong>diciplina</strong> como principios para un comportamiento apropiado.",
      },
      {
        id: 5,
        alto: "d-flex align-items-center",
        ancho: "justify-content-start",
        columna: "4",
        margin: "5",
        // title: "APP-TODO-TIC.COM",
        text: "Mantenemos una <strong>búsqueda</strong> permanente del <strong>conocimiento</strong> amplio y profundo <strong>adquirido</strong> con el estudio y la <strong>experiencia</strong> para luego aplicarlo a la <strong>solución de problemas</strong>.",
      },
    ],
  }),
};
</script>

<style>
</style>
export default {
	data: () => ({
		loading: false,
		items: [],
		destinations: [],
		search: '',
		headers_origins: [
			{ text: 'Lugar', value: 'place', align: 'start', sortable: true },
			{ text: 'Municipio', value: 'municipalities.name', align: 'start', sortable: true },
			{ text: 'Departamento', value: 'municipalities.departments.name', align: 'start', sortable: true },
			{ text: 'País', value: 'municipalities.departments.country.name', align: 'start', sortable: true },

			{ text: 'Acciones', value: 'actions', sortable: false },
		],
		item_aux: {},
		isEdit: false,
		isDelete: false,
		isOrigin: false,
		isDestination: false,
		dialog_edit: false,
		municipalities: [],
		valid: true,

	}),

	async created() {

		let r1 = await this.get_all_origins();
		let r2 = await this.get_all_municipalities();

		await Promise.all([r1, r2]);
	},

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},
	},

	methods: {

		close_dialog_edit() {
			try {
				if (!this.isDelete) this.$refs.formDialogEdit.reset();
				this.dialog_edit = false;
				this.isEdit = false;
				this.isDelete = false;
				this.item_aux = {
					municipality_id: '',
					name: '',
				};
			} catch (error) {
				console.log(error);
			}
		},

		modificar_item(item) {
			this.item_aux = Object.assign({}, item);
			console.log(this.item_aux);
			this.isDelete = false;
			this.dialog_edit = true;
			this.isEdit = true;
		},

		exportarExcel() {
			let data = XLSX.utils.json_to_sheet(this.origins)
			const workbook = XLSX.utils.book_new()
			const filename = 'origenes'
			XLSX.utils.book_append_sheet(workbook, data, filename)
			XLSX.writeFile(workbook, `${filename}.xlsx`)
		},

		delete_item(item) {
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		crear_item() {
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;
		},

		acept_edit() {
			if (this.isEdit) {
				console.log("editando", this.item_aux);
				if (this.isOrigin) {
					this.update_origin();
				} else if (this.isDestination) {
					this.update_destination();
				}
				this.close_dialog_edit();
			} else {
				console.log("creando", this.item_aux);
				delete this.item_aux.id;

				if (this.isOrigin) {
					this.create_origin();
				}
				else if (this.isDestination) {
					this.create_destination();
				}
				this.close_dialog_edit();
			}
		},

		acept_delete() {
			if (this.isOrigin) {
				this.deleteOrigin(this.item_aux);
			} else if (this.isDestination) {
				this.delete_destination(this.item_aux);
			}
			this.close_dialog_edit();
		},

		async deleteOrigin(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.delete_origin(item.id);

				if (r.status != 200) throw new Error(r);

				this.$toast.success("Origen eliminado", { timeout: 2000 });
				this.get_all_origins();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar el origen", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_all_origins() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_origin();
				this.items = r.data;
				console.log(r.data);
				this.isOrigin = true;
				this.isDestination = false;
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los origenes", { timeout: 2000 });
				this.loading = false;
			}
		},

		async get_all_destinations() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_destination();
				this.items = r.data;
				console.log(r.data);
				this.isDestination = true;
				this.isOrigin = false;
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los destinos", { timeout: 2000 });
				this.loading = false;
			}
		},


		async get_all_municipalities() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.get_all_municipality();
				this.municipalities = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los municipios", { timeout: 2000 });
				this.loading = false;
			}
		},

		async create_origin() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.post_origin(this.item_aux);
				this.$toast.success("Origen creado", { timeout: 2000 });
				this.get_all_origins();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear el origen", { timeout: 2000 });
				this.loading = false;
			}
		},

		async update_origin() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.put_origin(this.item_aux);
				this.$toast.success("Origen actualizado", { timeout: 2000 });
				this.get_all_origins();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar el origen", { timeout: 2000 });
				this.loading = false;
			}
		},

		async create_destination() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.post_destination(this.item_aux);
				this.$toast.success("Destino creado", { timeout: 2000 });
				this.get_all_destinations();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear el destino", { timeout: 2000 });
				this.loading = false;
			}
		},

		async update_destination() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.put_destination(this.item_aux);
				this.$toast.success("Destino actualizado", { timeout: 2000 });
				this.get_all_destinations();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar el destino", { timeout: 2000 });
				this.loading = false;
			}
		},

		async delete_destination(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.delete_destination(item.id);
				this.$toast.success("Destino eliminado", { timeout: 2000 });
				this.get_all_destinations();
				this.close_dialog_edit();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("No es posible  eliminar el destino", { timeout: 2000 });
				this.loading = false;
			}
		},


	}

}


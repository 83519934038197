<template>
  <div>
  <v-alert class="text-center" dense dark color="white">
    <span style="color: #77A100; font-size:1.1rem"><strong>
        Bienvenido Conductor
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="#77A100">
                info
              </v-icon>
            </v-btn>
          </template>
          <span>Puedes iniciar o finalizar un viaje</span>
        </v-tooltip>

      </strong>
    </span>

  </v-alert>
  <v-container class="info_img">
    <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" class="d-flex justify-content-center">
        <v-img :src="logo_back" width="10%" height="500px" contain>
        </v-img>
      </v-card>
    </v-hover>
  </v-container>
  </div>
</template>
<script>
import logo_back from "../../assets/City-driver.svg";
export default {
  data() {
    return {
      logo_back,
    };
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:is(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}


.info_img {
  display: grid;
  /* Usa flexbox para centrar verticalmente */
  align-items: center;
  /* Centra verticalmente en el eje Y */
  justify-content: center;
  /* Centra horizontalmente en el eje X */
  /* height: 90vh; */
  /* Establece la altura del contenedor al 100% de la altura de la ventana */


}
</style>
import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "Nombres",
          align: "center",
          sortable: true,
          value: "name",
        },
				{
					text: "last_name",
					align: "center",
					sortable: true,
					value: "last_name",
				},
				{
					text: "email",
					align: "center",
					sortable: true,
					value: "email",
				},
				{
					text: "CUI/Pasaporte",
					align: "center",
					sortable: true,
					value: "cui_passport",
				},
				{
					text: "Rol",
					align: "center",
					sortable: true,
					value: "role_id",
				},
				{
					text: "¿Email confirmado?",
					align: "center",
					sortable: true,
					value: "emailConfirmation",
				},
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],

			items: [],

			item_aux: {
				id: -1,
				name: '',
				last_name: '',
				email: '',
				cui_passport: '',
				role_id: '',
				emailConfirmation: false,
				password: '',
			},
			dialog_edit: false,
			isEdit: false,
			isDelete: false,
			show1: false,
			roles: [
				{num : 2, name: 'Administrador'},
				{num : 3, name: 'Conductor'},
				{num : 4, name: 'Cliente'},
				{num : 5, name: 'Colaborador'},
			],
			valid: true,
    };
  },
  async created() {
		// crea una promesa para obtener las marcas y los tipos
		let p1 = this.getUser();

		await Promise.all([p1]);

  },

	watch: {
		dialog_edit(val) {
			val || this.close_dialog_edit();
		},
	},
	
  methods: {

		async getUser() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.getUser();
				this.items = r.data;
				console.log(r.data);
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al obtener los usuarios", { timeout: 2000 });
				this.loading = false;
			}
		}, 

		async deleteVehicle(item) {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.deleteVehicle(item.id);
				this.$toast.success("Usuario eliminado", { timeout: 2000 });
				this.getUser();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al eliminar al usuario", { timeout: 2000 });
				this.loading = false;
			}
		},

		async createUser() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.createUser(this.item_aux);
				this.$toast.success("Usuario creado", { timeout: 2000 });
				this.getUser();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al crear el usuario", { timeout: 2000 });
				this.loading = false;
			}
		},

		async updateVehicle() {
			try {
				this.loading = true;
				let r = await this.$store.state.services.administradorService.updateVehicle(this.item_aux);
				this.$toast.success("Usuario actualizado", { timeout: 2000 });
				this.getUser();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.$toast.error("Error al actualizar al usuario", { timeout: 2000 });
				this.loading = false;
			}
		},

    exportarExcel() {
      let data = XLSX.utils.json_to_sheet(this.items)
      const workbook = XLSX.utils.book_new()
      const filename = 'Tipos_vehiculos'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

		modificar_item(item){
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isEdit = true;
			this.isDelete = false;

		},

		crear_item(){
			this.dialog_edit = true;
			this.isEdit = false;
			this.isDelete = false;

		},
		
		close_dialog_edit() {
			try {
				if(!this.isDelete) this.$refs.formDialogEdit.reset();
				this.dialog_edit = false;
				this.isEdit = false;
				this.isDelete = false;
				this.item_aux = {
					id: '',
					name: '',
				};
			} catch (error) {
				console.log(error);
			}
		},

		acept_edit() {
			if(this.isEdit){
				console.log("editando", this.item_aux);
				this.updateVehicle();
				this.close_dialog_edit();
			}else{
				console.log("creando", this.item_aux);
				delete this.item_aux.id;
				this.createUser();
				this.close_dialog_edit();
			}
		},

		delete_item(item){
			console.log("eliminando", item);
			this.item_aux = Object.assign({}, item);
			this.dialog_edit = true;
			this.isDelete = true;
		},

		acept_delete(){
			this.deleteVehicle(this.item_aux);
			this.close_dialog_edit();
		}



   


  },
};
import tabla_tarjetas from "@/components/shared/tabla/tabla.vue"

import CryptoJS from "crypto-js";

export default {
  components: { tabla_tarjetas },

  data() {
    return {
      color1: '',
      color2: '',
      color3: '',
      color4: '',
      color5: '',
      color6: '#142638',
      color7: '',
      tab: 'tab-6',

      valid: true,
      validCambiarContrasena: true,

      nitRules: [
        v => !!v || 'El nit es obligatorio',
      ],
      password_agencia: [
        v => !!v || 'La contraseña de su agencia virtual es obligatoria',
      ],

      password: [
        v => !!v || 'La contraseña de su cuenta es obligatoria',
      ],

      password_cuenta: [
        v => !!v || 'La contraseña de su cuenta es obligatoria',
      ],

      password_nueva: {
        a: (v) => !!v || "La confirmación de contraseña es obligatoria",
        b: (v) => (v && v.length >= 9) || 'La contraseña debe tener minímo 9 caracteres',
        c: (v) => /[0-9]+/.test(v) || 'Debe contener al menos un número',
        d: (v) => /[!-/]+|@+/.test(v) || 'Debe contener al menos un 1 caracter especial',
      },


      dpiRules: [v => !!v || 'El CUI es obligatorio para cambiar la contraseña'],

      confirmarRules: {
        a: (v) => !!v || "La confirmación de contraseña es obligatoria",
        b: (v) => (v && v.length >= 9) || 'La contraseña debe tener minímo 9 caracteres',
        c: (v) => (v === this.actualizar_contrasena.password_nueva) || 'Las conttraseñas no coinciden'
      },

      informacion_general: {
        primer_nombre: '',
        segundo_nombre: '',
        primer_apellido: '',
        segundo_apellido: '',
        fecha_nacimiento: '',
        direccion: '',
        municipio_id: '',
        departamento_id: '',
        presenta_retencion: false,
      },

      datos_tributarios: {
        nuevo_nit: '',
        password_banca: '',
        password: ''
      },

      default_datos_tributarios: {
        nuevo_nit: '',
        password_banca: '',
        password: ''
      },

      actualizar_contrasena: {
        cui: '',
        password_nueva: '',
        password_confi: '',
        password_vieja: '',
      },

      default_actualizar_contrasena: {
        cui: '',
        password_nueva: '',
        password_confi: '',
        password_vieja: '',
      },

      //PASSWORD DE AGENCIA VIRTUAL Y TIC
      show1: false,
      show2: false,

      //CAMBIOS DE PASSWORD
      showPass: false,
      showPassC: false,
      showPassA: false,

      loading: false,
      loadingTabla: false,

      dialogDatosTributarios: false,
      dialogCancelarSuscripcion: false,

      filasTarjetas: [],
      columnasTarjetas: [
        { text: 'Nombre Portador', value: 'nombre_portador' },
        { text: 'Tarjeta principal', value: 'activo' },
        { text: 'Acciones', value: 'acciones' }
      ],
      nombre_tabla_tarjeta: "",
      tarjetaNueva: {
        no: "",
        nombre_portador: "",
        fecha_expiracion: "",
        contratos: false,
      },

      suscripcion: 0, //mensual 1 , anual 2
      suscripcion_actual: 0,
      suscripcion_id: 0,
      selecciona1: "card gr-2 rounded",
      selecciona2: "card gr-2 rounded",
      opt1: false,
      opt2: false,

      usuario_id: 0,
      completo: false,


      correo_encabezados: [
        { text: 'Correo', value: 'correo' },
        { text: '¿Está Activo?', value: 'activo' },
        { text: '¿Está verificado?', value: 'verificado' },
        { text: 'Opciones', value: 'accion' }
      ],
      correos: [],

      suscripciones: [],
      loading_correos: false,
      loading_suscripciones: false,
      acepto_terminos_fin: null,

      dialog_cambiar_suscripcion: false,
      suscripcion_nueva_id: null,

      dialog_modificar_correo: false,
      item_correo_aux: '',
      correo_nuevo: '',

      fecha: '',
      menu2: false,

      departamentos: [],
      municipios: [],

      loading_departamentos: false,

      datos_tarjeta: {
        token: '',
        ultimos_numeros: '',
        nombre_portador: '',
      },

      src_contrato: "",
      loading_documento_finalizacion:false,

      regimen_id: -1,

      columnas_emisores: [
        {
          text: "TIPO DE EMISOR",
          align: "center",
          sortable: true,
          value: "tipo_emisor.descripcion",
        },
        {
          text: "INCLUIR",
          align: "center",
          sortable: true,
          value: "incluir",
        }
      ],

      filas_tipo_perfil_declaracion: [],

      loading_datos_tributarios: false,
      actividad_economica_id: -1,
      buscar_tipo_emisores: "",

    }
  },

  computed: {
    size() {
      const size = { xs: "x-small", sm: 'small', md: 'medium', lg: 'large', xl: 'x-large' }[this.$vuetify.breakpoint.name];
      return size ? {
        [size]: true
      } : {}
    }
  },

  watch: {
    suscripcion() {
      if (this.suscripcion_actual != this.suscripcion) {
        console.log("tirar pregunta");
      }
    }
  },

  async created() {




    let informacion_incriptada = sessionStorage.getItem('dsgfv/&#$%sdksdn..sd5454sd-todo');
    let key_pass = atob(sessionStorage.getItem('dsgfv/&#$%sdksdn..sd5454sd-tic'))
    let bytes = CryptoJS.AES.decrypt(informacion_incriptada, key_pass + "byTeamT0d0T1c");
    //Casteo de información a estring
    let informacion_desincriptada = bytes.toString(CryptoJS.enc.Utf8);
    //Casteo de JSON String a Object json
    let r = JSON.parse(informacion_desincriptada)
    console.log("info usuario",r);

   


    this.usuario_id = r.usuario[0].id;
    this.actividad_economica_id = r.usuario[0].actividad_economica_id;
    this.regimen_id = r.usuario[0].regimen_id;
    this.actualizar_contrasena.cui = r.usuario[0].cui;

    this.seleccion_aux = {};

    await this.getFaltantes();
    await this.obtener_correos()
  },

  methods: {

    async getTipoPerfilDeclaracion(user_id) {
      try {
          this.loading_datos_tributarios = true;
          let r = await this.$store.state.services.usuarioService.getPerfilDeclaracionUsuario(user_id);
          r.data.data.filter((item) => {
            item.loading = false;
          });
          // ordena los datos por tipo de emisor alfabeticamente
          r.data.data.sort((a, b) => {
            if (a.tipo_emisor.descripcion < b.tipo_emisor.descripcion) {
              return -1;
            }
            if (a.tipo_emisor.descripcion > b.tipo_emisor.descripcion) {
              return 1;
            }
            return 0;
          });
          
          this.loading_datos_tributarios = false;
          this.filas_tipo_perfil_declaracion = r.data.data;
          console.log("tipo perfil declaracion", this.filas_tipo_perfil_declaracion);  
        } catch (error) {
          console.log(error);
          this.$toast.error("No es posible obtener el tipo de perfil de declaración");
        }
      },

    async getInfoBancaSat() {
      try {
        let r = await this.$store.state.services.usuarioService.getInfoBancaSat()
        console.log("info banca sat");
        console.log(r);

        this.datos_tributarios.nuevo_nit = r.data.data.nit;
        this.datos_tributarios.password_agencia = r.data.data.password_sat;

      } catch (error) {
        console.log(error);
        this.$toast.error("No es posible verificar la información de la banca sat")
      }
    },

    convertirnumero(numero) {
      let dollarUSLocale = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
      return dollarUSLocale.format(Math.abs(numero))
    },

    setFecha() {
      this.fecha = this.informacion_general.fecha_nacimiento.toString().split("-").reverse().join('/')
    },

    async modificar_perfil_declaracion(item){
      try {
        item.loading = true;
        // si el item incluir es 1 se cambia a 0 y viceversa
        item.incluir = item.incluir == 1 ? 0 : 1;
        let r = await this.$store.state.services.usuarioService.updatePerfilDeclaracion(item);
        console.log(r);
        item.loading = false;
        this.$toast.success("Perfil de declaración modificado correctamente");
        this.getTipoPerfilDeclaracion(this.usuario_id);

      } catch (error) {
        console.log(error);
        this.$toast.error("No es posible modificar el perfil de declaración")
      }
    },

    async getFaltantes() {

      try {
        let r = await this.$store.state.services.usuarioService.getUsuarioFaltante(this.usuario_id);
        // console.log(r);
        if (r.data.success) {
          if (r.data.data.forma_pago.length == 0) {
            this.completo = false;
          } else {
            this.completo = true;

            // obtener parametros de la ruta
            let params = this.$route.params;
            if(params.tab == 7){
              this.color1 = '';
              this.color2= '';
              this.color3 = '';
              this.color4 = '';
              this.color5 = '';
              this.color6 = '';
              this.color7 = '#142638';
              this.tab = 'tab-7';
              this.getTipoPerfilDeclaracion(this.usuario_id);
            }
          }
        } else {
          this.$toast.error("Error al válidar la información ingresada")
          this.completo = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async seleccionaEdicion(item) {
      if (item == 1) {
        this.color1 = "#142638"
        this.color2 = ""
        this.color3 = ""
        this.color4 = ""
        this.color5 = ""
        this.color6 = ""
        this.color7 = ""
        await this.getDeps();
        await this.getInfoUsuario();

      } else if (item == 2) {
        this.color1 = ""
        this.color2 = "#142638"
        this.color3 = ""
        this.color4 = ""
        this.color5 = ""
        this.color6 = ""
        this.color7 = ""

      } else if (item == 3) {
        this.color1 = ""
        this.color2 = ""
        this.color3 = "#142638"
        this.color4 = ""
        this.color5 = ""
        this.color6 = ""
        this.color7 = ""

        await this.getListaFormaPago();
      } else if (item == 4) {
        this.color1 = ""
        this.color2 = ""
        this.color3 = ""
        this.color4 = "#142638"
        this.color5 = ""
        this.color6 = ""
        this.color7 = ""

        await this.getInfoBancaSat();
      } else if (item == 5) {
        this.color1 = ""
        this.color2 = ""
        this.color3 = ""
        this.color4 = ""
        this.color5 = "#142638"
        this.color6 = ""
        this.color7 = ""

        await this.getSuscripcionActual();
        await this.getTiposSuscripcionActivas();
      } else if (item == 6) {
        this.color1 = ""
        this.color2 = ""
        this.color3 = ""
        this.color4 = ""
        this.color5 = ""
        this.color6 = "#142638"
        this.color7 = ""

      } else if (item == 7) {
        this.color1 = ""
        this.color2 = ""
        this.color3 = ""
        this.color4 = ""
        this.color5 = ""
        this.color6 = ""
        this.color7 = "#142638"
        await this.getTipoPerfilDeclaracion(this.usuario_id);
      }
    },

    async obtener_correos() {
      try {
        this.loading_correos = true;
        let r = await this.$store.state.services.usuarioService.getListadoCorreo();
        console.log(r);
        this.correos = r.data.data;
        this.loading_correos = false;

      } catch (error) {
        this.loading_correos = false;

        console.log(error);
      }
    },

    async reenviar_correo(item) {
      try {
        let r = await this.$store.state.services.usuarioService.reenviarCorreo({ id: item.id });
        console.log(r);
        this.correos = r.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    actualizar() {
      if (this.$refs.form.validate()) {
        this.dialogDatosTributarios = true;
      }
    },

    async confirmar_actualizar() {
      this.loading = true;
      try {
        let r = await this.$store.state.services.usuarioService.actualizar_tributarios(this.datos_tributarios);
        console.log(r);
        if (r.data.success) {
          this.$toast.success(r.data.message);
        } else {
          this.$toast.error(r.data.message);
        }
        this.loading = false;
        this.dialogDatosTributarios = false;
        this.datos_tributarios = this.dialogDatosTributarios;
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al contactar con el servidor, para actualizar datos tributarios")
        this.loading = false;
      }
    },

    async actualizarPassword() {
      if (this.$refs.formCambiarContrasena.validate()) {
        try {
          this.loading = true;
          let r = await this.$store.state.services.usuarioService.cambiarContrasena(this.actualizar_contrasena);
          console.log(r);

          if (r.data.success) {
            this.$toast.success("Contraseña actualizada exitosamente");
            this.loading = false;
          } else {
            this.$toast.error("No es posible actualizar la contraseña, revisa tus datos, " + r.data.message);
            this.loading = false;
          }
          // this.actualizar_contrasena = this.default_actualizar_contrasena;
        } catch (error) {
          console.log(error);
        }
      }
    },

    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async actualizarInformacion() {
      console.log("prueba");
    },

    //FORMA DE PAGO
    async getListaFormaPago() {
      try {
        this.loadingTabla = true;
        let r = await this.$store.state.services.usuarioService.getListaFormaPago();
        console.log(r);
        this.filasTarjetas = r.data.data;
        this.loadingTabla = false;
      } catch (error) {
        console.log(error);
      }
    },

    async cambiarEstadoTarjeta(item) {
      try {
        this.loadingTabla = true;
        let r = await this.$store.state.services.usuarioService.activarTarjeta(item.id)
        console.log(r);
        this.loadingTabla = false;
        await this.getListaFormaPago();
      } catch (error) {
        console.log(error);
        this.loadingTabla = false;
      }
    },

    async eliminarTarjeta(item) {
      try {
        this.loadingTabla = true;
        let r = await this.$store.state.services.usuarioService.eliminarTarjeta({ forma_pago_id: item.id, password: this.datos_tributarios.password })
        console.log(r);
        this.loadingTabla = false;
        await this.getListaFormaPago();
      } catch (error) {
        console.log(error);
        this.loadingTabla = false;
      }
    },

    async crearTarjeta(card) {
      console.log(card);
      this.tarjetaNueva.no = card.cardNumber;
      this.tarjetaNueva.nombre_portador = card.cardName;
      this.tarjetaNueva.fecha_expiracion = card.cardYear + '-' + card.cardMonth + '-01';
      this.tarjetaNueva.contratos = card.checkbox;
      if (new Date(card.cardYear, card.cardMonth) <= new Date(new Date().getFullYear(), new Date().getMonth())) {
        this.$toast.error("La tarjeta proporcionada está vencida o esta por vencer");
        return;
      }
      try {
        let info = JSON.parse(atob(sessionStorage.getItem("CARD_REQUEST")))
        console.log("esta es la información de mi tarjeta");
        console.log(info);

        let info_tarjeta = {
          token: info.TokenDetails.AccountToken,
          numero: info.TokenDetails.CardNumber,
          client: info.TokenDetails.CardHolderName,
          amount: 1.00,
          cvv: card.cardCvv,
        }

        try {
          this.loading = true;
          let r = await this.$store.state.services.usuarioService.postPagoSuscripcionSale(info_tarjeta);
          console.log(r);
          let respuesta = this.parseXmlToJson(r.data);
          console.log("respuesta");
          console.log(respuesta);
          if (respuesta.SaleResponse.SaleResult.Code === "00") {


            this.loading = false;
            console.log("respuesta id");
            console.log(respuesta.SaleResponse.SaleResult.TransactionId);
            try {
              let r = await this.$store.state.services.usuarioService.postPagoVoid({ referenceId: respuesta.SaleResponse.SaleResult.TransactionId });
              console.log("respuesta de void");
              console.log(r);
              let respuesta_void = this.parseXmlToJson(r.data);
              console.log("respuesta void");
              console.log(respuesta_void);

              if (respuesta_void.VoidResponse.VoidResult.Code === "00") {
                this.$toast.success("Tarjeta de crédito aprobada con éxito", { timeout: 5000 })
                try {
                  this.loadingTabla = true;
                  let r = await this.$store.state.services.usuarioService.insertarTarjeta(this.tarjetaNueva);
                  console.log(r);
                  this.loadingTabla = false;
                  sessionStorage.removeItem("CARD_REQUEST");
                  await this.getListaFormaPago();
                } catch (error) {
                  console.log(error);
                  this.$toast.error("La tarjeta proporcionada, no es posible registrarla");
                }
              } else {
                this.$toast.error("Error al aprobar la tarjeta de crédito", { timeout: 5000 })
              }
            } catch (error) {
              console.log(error);
              this.$toast.error("Error al contactar con el servidor, para concluir el registro de la tarjeta de crédito", { timeout: 5000 })
            }
          } else {
            this.loading = false;
            this.e1 = 3;
            if (respuesta.SaleResponse.SaleResult.Code === "51") {
              this.$toast.error("Error al realizar el pago, Fondos insuficientes", { timeout: 5000, position: "top-right" })
            } else if (respuesta.SaleResponse.SaleResult.Code === "05") {
              this.$toast.error("Error al realizar el pago, Tajerta declinada, contacte al proveedor de la tarjeta de crédito", { timeout: 5000, position: "top-right" })
            } else if (respuesta.SaleResponse.SaleResult.Code === "111") {
              this.$toast.error("Error al realizar el pago, Transacción denegada TID inválido", { timeout: 5000, position: "top-right" })
            } else if (respuesta.SaleResponse.SaleResult.Code === "57") {
              this.$toast.error("Error al realizar el pago, Tarjeta bloqueada o cancelada por el banco", { timeout: 5000, position: "top-right" })
            } else if (respuesta.SaleResponse.SaleResult.Code === "132") {
              this.$toast.error("Error al realizar el pago, Declinada, tarjeta reportada como perdida/robada", { timeout: 5000, position: "top-right" })
            }
            sessionStorage.removeItem("CARD_REQUEST");
            // this.volver();
          }
        } catch (error) {
          console.log(error);
          this.$toast.error("Error al realizar el pago, verifique los datos de su tarjeta de crédito", { timeout: 5000 })
          sessionStorage.removeItem("CARD_REQUEST");
          this.volver();
          this.e1 = 3;
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al contactar con el servidor, para crear tarjeta", { timeout: 5000 });
      }
      console.log("aqui me detengo");
    },

    parseXmlToJson(xml) {
      const json = {};
      for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
        const key = res[1] || res[3];
        const value = res[2] && this.parseXmlToJson(res[2]);
        json[key] = ((value && Object.keys(value).length) ? value : res[2]) || null;
      }
      return json;
    },

    async seleccionar_suscripcion(tipo) {
      console.log(tipo);
      this.seleccion_aux.nombre = tipo.nombre;
      this.seleccion_aux.precio = tipo.precio;
      this.suscripcion_nueva_id = tipo.id
      console.log("quiere cambiar");
      this.dialog_cambiar_suscripcion = true;
      await this.getTokenTarjeta();
      console.log(this.suscripcion_nueva_id);
    },

    cancelar_resuscripcion() {
      this.dialog_cambiar_suscripcion = false;
      this.seleccion_aux = {};
      this.datos_tarjeta.token = "";
      this.datos_tarjeta.ultimos_numeros = "";
      this.datos_tarjeta.nombre_portador = "";

    },


    async confirmar_cambiar_suscripcion() {
      console.log("cambio");

      let info_tarjeta = {
        token: this.datos_tarjeta.token,
        client: this.datos_tarjeta.nombre_portador,
        amount: this.seleccion_aux.precio
      }
      console.log(info_tarjeta);

      try {
        this.loading = true;
        let r = await this.$store.state.services.usuarioService.postPagoSuscripcionRebill(info_tarjeta);
        console.log(r);
        let respuesta = this.parseXmlToJson(r.data);
        console.log("respuesta");
        console.log(respuesta);
        if (respuesta.RebillResponse.RebillResult.Code === "00") {
          this.loading = false;
          console.log("respuesta id");
          console.log(respuesta.RebillResponse.RebillResult.TransactionId);
          this.$toast.success("Pago realizado exitosamente", { timeout: 6000 });
          if (this.suscripcion_actual == 0) {
            try {
              this.loading = true;
              let r = await this.$store.state.services.usuarioService.postRenovarSuscripion({ suscripcion_nueva_id: this.suscripcion_nueva_id });
              console.log(r);
              this.$toast.success(r.data.message, { timeout: 7000 });

              this.loading = false;
              this.suscripcion_nueva_id = null;
              this.dialog_cambiar_suscripcion = false;
              this.cancelar_resuscripcion();
            } catch (error) {
              console.log(error);
              this.$toast.error("No es posible cambiar de suscripción")
              this.loading = false;
              this.suscripcion_nueva_id = null;
              this.dialog_cambiar_suscripcion = false;
              this.cancelar_resuscripcion();

            }
          } else {
            try {
              this.loading = true;
              let r = await this.$store.state.services.usuarioService.postCambiarSuscripcion({ suscripcion_nueva_id: this.suscripcion_nueva_id });
              console.log(r);
              this.$toast.success(r.data.message, { timeout: 7000 });
              this.loading = false;
              this.suscripcion_nueva_id = null;
              this.dialog_cambiar_suscripcion = false;
              this.cancelar_resuscripcion();

            } catch (error) {
              console.log(error);
              this.$toast.error("No es posible cambiar de suscripción")
              this.loading = false;
              this.suscripcion_nueva_id = null;
              this.dialog_cambiar_suscripcion = false;
              this.cancelar_resuscripcion();

            }
          }

        } else {
          this.loading = false;
          if (respuesta.RebillResponse.RebillResult.Code === "51") {
            this.$toast.error("Error al realizar el pago, Fondos insuficientes", { timeout: 5000, position: "top-right" })
          } else if (respuesta.RebillResponse.RebillResult.Code === "05") {
            this.$toast.error("Error al realizar el pago, Tajerta declinada, contacte al proveedor de la tarjeta de crédito", { timeout: 5000, position: "top-right" })
          } else if (respuesta.RebillResponse.RebillResult.Code === "111") {
            this.$toast.error("Error al realizar el pago, Transacción denegada TID inválido", { timeout: 5000, position: "top-right" })
          } else if (respuesta.RebillResponse.RebillResult.Code === "57") {
            this.$toast.error("Error al realizar el pago, Tarjeta bloqueada o cancelada por el banco", { timeout: 5000, position: "top-right" })
          } else if (respuesta.RebillResponse.RebillResult.Code === "132") {
            this.$toast.error("Error al realizar el pago, Declinada, tarjeta reportada como perdida/robada", { timeout: 5000, position: "top-right" })
          }
          sessionStorage.removeItem("CARD_REQUEST");
          this.cancelar_resuscripcion();

          // this.volver();
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al realizar el pago, verifique los datos de su tarjeta de crédito", { timeout: 5000 })
        sessionStorage.removeItem("CARD_REQUEST");
        this.cancelar_resuscripcion();
      }


    },

    async cancelar_suscripcion() {
      this.dialogCancelarSuscripcion = true;
      console.log("suscripcion_actual");
      console.log(this.suscripcion_id);
      try {
        this.loading_documento_finalizacion = true;
        let r = await this.$store.state.services.usuarioService.getDocumentoSuscripcion(this.suscripcion_id)
        console.log("respuesta documento",r);
        this.src_contrato = r.data.data;
        this.loading_documento_finalizacion = false;

      } catch (error) {
        this.loading_documento_finalizacion = false;
        console.log(error);
        this.$toast.error("No es posible obtener el documento para finalizar suscripción")
      }
    
    },

    async confirmar_cancelar() {
      if (this.acepto_terminos_fin != null) {
        try {
          this.loading = true;
          let r = await this.$store.state.services.usuarioService.cancelarSuscripcion({ id: this.suscripcion_id, acepta_fin_suscripcion: this.acepto_terminos_fin })
          console.log(r);
          if (r.data.success) {
            this.$toast.success("Hasta pronto, le llegará un correo electrónico para respaldar la cancelación de su contrato")
            this.loading = false;
            this.dialogCancelarSuscripcion = false;
          } else {
            this.$toast.error("Ha ocurrido un error, no es posible cancelar tu suscripción")
            this.loading = false;
            this.dialogCancelarSuscripcion = false;
          }
        } catch (error) {
          this.$toast.error("Ha ocurrido un error, no es posible cancelar tu suscripción")
          this.loading = false;
          this.dialogCancelarSuscripcion = false;
        }
      } else {
        this.$toast.info("Debes aceptar los términos de Finalización de suscripción")
      }
    },

    async getTiposSuscripcionActivas() {
      try {
        this.loading_suscripciones = true;
        let r = await this.$store.state.services.administradorService.getTiposSuscripcionActivas(this.regimen_id);
        this.suscripciones = r.data.data
        console.log("mis suscripciones son", this.suscripciones);
        this.suscripciones.forEach(element => {

          if (element.id == this.suscripcion_actual) {
            element.selecciona = 'card gr-1 rounded';
          } else {
            element.selecciona = 'card gr-2 rounded';
          }
        });
        this.loading_suscripciones = false;

      } catch (error) {
        console.log(error);
        this.loading_suscripciones = false;

      }
    },

    async getSuscripcionActual() {
      try {
        this.loading_suscripciones = true;
        let r = await this.$store.state.services.usuarioService.getSuscripcionActual();
        console.log("mi suscripcion actual es", r);
        this.suscripcion_actual = r.data.data[0].tipo_suscripcion_id;
        this.suscripcion_id = r.data.data[0].id;
        this.loading_suscripciones = false;
      } catch (error) {
        console.log(error);
        this.loading_suscripciones = false;
      }
    },

    modificar_correo(item) {
      this.item_correo_aux = item;
      this.dialog_modificar_correo = true;
    },

    async confimar_modificar_correo() {
      if (this.$refs.formCorreoNuevo.validate()) {
        try {
          this.loading_correos = true;
          let r = await this.$store.state.services.usuarioService.cambiar_correo_electronico({ usuario_id: this.item_correo_aux.usuario_id, correo_nuevo: this.correo_nuevo, correo_id: this.item_correo_aux.id });
          console.log(r);
          this.$toast.success(r.data.message);
          this.dialog_modificar_correo = false;
          this.loading_correos = false;
          this.obtener_correos();
        } catch (error) {
          console.log(error);
        }
      }
    },

    async getInfoUsuario() {
      try {
        let r = await this.$store.state.services.usuarioService.getInfoUsuario();
        console.log(r);
        this.informacion_general.primer_nombre = r.data.data[0].primer_nombre;
        this.informacion_general.segundo_nombre = r.data.data[0].segundo_nombre;
        this.informacion_general.primer_apellido = r.data.data[0].primer_apellido;
        this.informacion_general.segundo_apellido = r.data.data[0].segundo_apellido;
        this.informacion_general.telefono = r.data.data[0].telefono;
        this.informacion_general.direccion = r.data.data[0].direccion;
        this.informacion_general.fecha_nacimiento = r.data.data[0].fecha_nacimiento;
        this.setFecha();
        this.informacion_general.municipio_id = r.data.data[0].municipio_id;
        this.informacion_general.departamento_id = r.data.data[0].departamento_id;
        console.log("info general");
        console.log(this.informacion_general);
        if (r.data.data[0] == 0) {
          this.informacion_general.presenta_retencion = false;
        } else {
          this.informacion_general.presenta_retencion = true;
        }

      } catch (error) {
        console.log(error);
      }
    },

    async getDeps() {
      try {
        this.loading_departamentos = true;
        let r = await this.$store.state.services.registerService.departamentos();
        //console.log('r.data DEPTARTAMENTOS');
        this.loading_departamentos = false;

        this.departamentos = r.data.data;
        console.log(this.departamentos);
      } catch (error) {
        this.loading_departamentos = false;

        this.$toastr.error("Ocurrió un error al obtener los departamentos");
      }
    },

    verMuni() {
      if (this.informacion_general.departamento_id !== '') {
        // let dep = this.departamentos.filter(x => x.id === this.register.departamento)[0];
        this.municipios = this.departamentos.filter(x => x.id === this.informacion_general.departamento_id)[0].municipio;
        //console.log('this.municipio');
        //console.log(this.municipio);
        return true;
      }
    },

    async actualizar_informacion() {
      console.log("todo ok");
      try {
        let r = await this.$store.state.services.usuarioService.updateInfo(this.informacion_general);
        console.log(r);
        this.$toast.success("Datos actualizados");
      } catch (error) {
        console.log(error);
        this.$toast.error("No es posible modificar la información");

      }
    },

    async getTokenTarjeta() {
      try {
        let r = await this.$store.state.services.usuarioService.getTokenTarjeta();
        console.log("token");
        console.log(r);
        this.datos_tarjeta.token = r.data.data.token;
        this.datos_tarjeta.nombre_portador = r.data.data.nombre_portador;
        this.datos_tarjeta.ultimos_numeros = r.data.data.ultimos_numeros;
      } catch (error) {
        console.log(error);
        this.$toast.error("No posible obtener un metodo de pago");
      }
    }


  }
}